import React, { useEffect, useState } from "react";
import { newData } from "./Utils/Data";
import Row from "./Row";
import styled from "styled-components";
import Header from "./Header";
import CardContainer from "./CardContainer";
import AdminService from "../../../services/admin";

import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Grid,
} from "@material-ui/core";

const Sales = ({ teacher, dateFrom = null, dateTo = null }) => {
  const [sales, setSales] = useState([]);
  const [totalSale, setTotalSale] = useState();

  useEffect(() => {
    var data = {
      teacher_id: teacher,
    };
    if (dateFrom != null && dateTo != null) {
      data["dateFrom"] = dateFrom;
      data["dateTo"] = dateTo;
    }
    AdminService.getSalesByTeacher(data).then((res) => {
      setSales(res.data);
      var total = res.data.reduce(function (accumulator, currentValue) {
        return parseFloat(accumulator) + parseFloat(currentValue.sale_price);
      }, 0);
      setTotalSale(total);
    });
  }, []);

  return (
    <Box marginTop={1}>
      <div style={{ maxHeight: 400, overflow: "auto" }}>
        {" "}
        {/* Set the height and make it scrollable */}
        <Table size="small" aria-label="sales">
          <TableHead>
            <TableRow>
              <TableCell style={{ backgroundColor: "#F5F5F5" }}>Date</TableCell>
              <TableCell style={{ backgroundColor: "#F5F5F5" }}>
                Course
              </TableCell>
              <TableCell style={{ backgroundColor: "#F5F5F5" }} align="right">
                Amount
              </TableCell>
              <TableCell style={{ backgroundColor: "#F5F5F5" }} align="right">
                Sale Price
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sales.length > 0 ? (
              sales.map((sale) => (
                <TableRow>
                  <TableCell>{sale.created_at}</TableCell>
                  <TableCell>{sale.course_name}</TableCell>
                  <TableCell align="right">{sale.course_tution_fee}</TableCell>
                  <TableCell align="right">{sale.sale_price / 100}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No sales available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </Box>
  );
};

const Payouts = ({ teacher, dateFrom = null, dateTo = null }) => {
  const [payouts, setPayouts] = useState([]);
  const [totalPayouts, setTotalPayouts] = useState();

  useEffect(() => {
    var data = {
      teacher_id: teacher,
    };
    if (dateFrom != null && dateTo != null) {
      data["dateFrom"] = dateFrom;
      data["dateTo"] = dateTo;
    }
    AdminService.getPayoutsByTeacher(data).then((res) => {
      setPayouts(res.data);
      console.log(res.data);
      var total = res.data.reduce(function (accumulator, currentValue) {
        return parseFloat(accumulator) + parseFloat(currentValue.amount);
      }, 0);
      setTotalPayouts(total);
    });
  }, []);

  return (
    <Box marginTop={1}>
      <div style={{ maxHeight: 400, overflow: "auto" }}>
        <Table size="small" aria-label="payouts">
          <TableHead>
            <TableRow>
              <TableCell style={{ backgroundColor: "#F5F5F5" }}>Date</TableCell>
              <TableCell style={{ backgroundColor: "#F5F5F5" }}>
                Amount
              </TableCell>
              <TableCell style={{ backgroundColor: "#F5F5F5" }} align="right">
                Remarks
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payouts.length > 0 ? (
              payouts.map((payout) => (
                <TableRow>
                  <TableCell>{payout.created_at}</TableCell>
                  <TableCell>{payout.amount}</TableCell>
                  <TableCell align="right">{payout.remarks}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  No payouts available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </Box>
  );
};

export default function CollapsibleTable({ currentUser }) {
  const [rows, setRows] = React.useState(newData);
  const [toggle, setToggle] = React.useState("sales");

  // pagination hooks and functions
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <HeadingTop>Earning Summary</HeadingTop>
      <CardContainer teacher={currentUser} />
      <Section>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography style={{ fontSize: 22, paddingLeft: 10 }} variant="h4">
              {toggle === "sales" ? "Sales" : "Payouts"}
            </Typography>
          </Grid>
          <Grid item style={{ paddingRight: 10 }}>
            <Header setToggle={setToggle} toggle={toggle} />
          </Grid>
        </Grid>
        {toggle === "sales" ? (
          <Sales teacher={currentUser} />
        ) : (
          <Payouts teacher={currentUser} />
        )}
      </Section>
    </>
  );
}

const Heading = styled.span`
  font-weight: bold;
  font-size: 14px;
`;

const Section = styled.div`
  padding: 0 20px 0 20px;
  margin-top: 50px;
`;

const HeadingTop = styled.h2`
  font-weight: bolder;
  padding: 0 20px;
  flex: 1;
`;
