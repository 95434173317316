import React, { useState, useEffect } from "react";
import * as Mui from "@material-ui/core";
import * as MuiL from "@material-ui/lab";
import "../css/myProfile.css";
import "../css/teacherReg.css";
import TeacherService from "../../services/teacher";
import { UserContext } from "../../context/UserContext";
import PdfViewer from "./modals/PdfViewer";
import ImageViewer from "./modals/ImageViewer";
import LinearLoader from "../shared/loaders/linearLoader";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import DeleteMyAccount from "../widgets/DeleteMyAccount";
import { DatePicker, Button, Typography } from "antd";
import "antd/dist/antd.css";
import { Calender } from "./Lessons";
import ListOfTeachersLessons from "./Lessons/ListOfTeachersLessons";
import ListOfTeachersTrials from "./Lessons/ListOfTeachersTrials";
import Colors from "./Lessons/colors.json";
import ClassesTabs from "./Classes/ClassesTabs";

export default function ClassesInformation(props) {
  const [submitting, setSubmission] = React.useState(false);
  const [viewPdf, setViewPdf] = React.useState(false);
  const [fileName, setFileName] = React.useState("");
  const [viewFile, setViewFile] = React.useState(false);
  const { user } = React.useContext(UserContext);
  const { notification } = React.useContext(UserContext);
  const [teacherNotification, setTeacherNotification] = notification;
  const [currentUser, setCurrentUser] = user;
  const [userLanguages, setUserLanguages] = React.useState([]);
  const [userTrials, setUserTrials] = React.useState([]);
  const [trialsList, setTrialslist] = React.useState([]);
  const [success, setSuccess] = React.useState(null);
  const [responseMessage, setMessage] = React.useState(null);
  const [filePath, setPath] = React.useState(null);
  const [qualifications, setQualifications] = React.useState([]);
  const [qualificationsNew, setNewQualifications] = React.useState([]);
  const [loaded, setLoaded] = React.useState(0);
  const [updated, setUpdated] = React.useState(false);
  const newTimeSlotRef = React.useRef();
  const durationRef = React.useRef(null);
  const priceRef = React.useRef(null);
  const slotRef = React.useRef(null);
  const [deleteSlot, setDeleteSlot] = React.useState(0);
  const [updatedSlots, setUpdatedSlots] = React.useState([]);
  // const [qualificationsNew, setNewQualifications] = React.useState(null)
  const languageInput = { id: 1, language: "", level: "" };
  const [languagesInput, setLanguagesInput] = React.useState([languageInput]);
  const trialInput = { id: 1, duration: "", price: "", venue: "", slots: [] };
  const [trialsInput, setTrialsInput] = React.useState([trialInput]);
  const [state, setState] = React.useState({
    first_name: "",
    last_name: "",
    country: "",
    title: "",
    sub_title: "",
    display_name: "",
    password: "",
    confirmpassword: "",
    phone_number: "",
    profile_picture: props.profile_picture,
    about: "",
    bank_name: "",
    account_holder_name: "",
    routing_number: "",
    account_number: "",
    id_number: "",
    id_doc_back: null,
    id_doc_front: null,
    user_id: null,
    username: null,
    email: null,
  });
  const style = {
    position: "absolute",
    top: "40%",
    left: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "10",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };
  const [open, setOpen] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(false);
  const [slotOpen, setSlotOpen] = useState(false);
  const [lessons, setLessons] = useState([]);
  const [monthWise, setMonthWise] = useState([]);
  const [monthWiseLessons, setMonthWiseLessons] = useState([]);
  const [handleEditSlot, setHandleEditSlot] = useState();

  const handleClose = () => {
    setSlotOpen(false);
  };

  const ConfirmationModel = (id) => {
    return (
      <div>
        <Mui.Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Mui.Box sx={{ ...style, width: 300, borderRadius: 10 }}>
            <h1 style={{ marginBottom: 20, fontSize: 20 }}>Are you Sure?</h1>
            <div>
              <Mui.Button onClick={handleClose} className="no-btn">
                No
              </Mui.Button>
              <Mui.Button
                className="yes-btn"
                onClick={() => deleteFileHandler()}
              >
                Yes
              </Mui.Button>
            </div>
          </Mui.Box>
        </Mui.Modal>
      </div>
    );
  };

  const handleSlotClose = () => {
    setDeleteSlot(0);
  };

  const ConfirmationSlotModel = () => {
    return (
      <div>
        <Mui.Modal
          open={deleteSlot !== 0}
          onClose={handleSlotClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Mui.Box sx={{ ...style, width: 300, borderRadius: 10 }}>
            <h1 style={{ marginBottom: 20, fontSize: 20 }}>Are you Sure?</h1>
            <div>
              <Mui.Button onClick={handleSlotClose} className="no-btn">
                No
              </Mui.Button>
              <Mui.Button
                className="yes-btn"
                onClick={() => deleteSlotHandler(deleteSlot)}
              >
                Yes
              </Mui.Button>
            </div>
          </Mui.Box>
        </Mui.Modal>
      </div>
    );
  };

  const PdfModal = () => {
    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "70%",
      height: "95vh",
      bgcolor: "background.paper",
      border: "2px solid #E1E1E1",
      borderRadius: 15,
      boxShadow: 24,
      p: 4,
    };
    return (
      // <div>
      <Mui.Modal
        open={viewPdf}
        onClose={() => handleFileView(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Mui.Box sx={style}>
          <Mui.Icon
            onClick={() => handleFileView(false)}
            className="modal-close-btn"
          >
            close
          </Mui.Icon>
          <h2 class="parent-modal-title">{fileName}</h2>
          <PdfViewer style={{ overflowY: "scroll" }} url={filePath} />
        </Mui.Box>
      </Mui.Modal>
      // </div>
    );
  };

  const ImageModal = () => {
    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "70%",
      height: "95vh",
      bgcolor: "background.paper",
      border: "2px solid #E1E1E1",
      borderRadius: 15,
      boxShadow: 24,
      overflowY: "scroll",
      overflowX: "hidden",
      p: 4,
    };
    return (
      // <div>
      <Mui.Modal
        open={viewFile}
        onClose={() => handleFileView(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Mui.Box sx={style}>
          <Mui.Icon
            onClick={() => handleFileView(false)}
            className="modal-close-btn"
          >
            close
          </Mui.Icon>
          <h2 class="parent-modal-title">{fileName}</h2>
          <ImageViewer style={{ overflowY: "scroll" }} url={filePath} />
        </Mui.Box>
      </Mui.Modal>
      // </div>
    );
  };

  const handleFileView = (show, path = null, type = null, name = null) => {
    if (type == "pdf") {
      setPath(path);
      setFileName(name);
      setViewPdf(show);
    }
    if (type == null) {
      setViewPdf(false);
      setViewFile(false);
    }
    if (type == "jpg" || type == "png" || type == "jpeg") {
      setPath(path);
      setFileName(name);
      setViewFile(show);
    }
  };

  React.useEffect(() => {
    if (props.profile != null) {
      setQualifications(props.profile.qualifications);
      setUserLanguages(props.profile.languages);

      // console.log(props.profile.trial_prices);
      if (Array.isArray(props.profile.trial_prices))
        setUserTrials(props.profile.trial_prices);
      // console.log(props.profile.trial_prices);
      setLoaded(1);
      setState({
        ...state,
        first_name: props.profile.user_details.first_name,
        title: props.profile.title,
        sub_title: props.profile.sub_title,
        country: props.profile.country,
        user_id: props.profile.user_id,
        username: props.profile.user.username,
        email: props.profile.user.email,
        last_name: props.profile.user_details.last_name,
        display_name: props.profile.user_details.display_name,
        phone_number: props.profile.phone_number,
        about: props.profile.about,
        bank_name:
          props.profile.bank_details != null
            ? props.profile.bank_details.bank_name
            : null,
        account_holder_name:
          props.profile.bank_details != null
            ? props.profile.bank_details.account_holder_name
            : null,
        routing_number:
          props.profile.bank_details != null
            ? props.profile.bank_details.routing_number
            : null,
        account_number:
          props.profile.bank_details != null
            ? props.profile.bank_details.account_number
            : null,
        stripe_document_status:
          props.profile.bank_details != null
            ? props.profile.bank_details.stripe_document_status
            : null,
        id_number: null,
        stripe_connect_id:
          props.profile.user.stripe_connect_id == null
            ? "null"
            : props.profile.user.stripe_connect_id,
      });
    }
  }, [props.profile]);

  React.useEffect(() => {
    setTrialslist([
      ...userTrials
        .map((trial) => {
          if (trial.slots[0]) {
            return {
              id: trial.price_id,
              date: trial.slots[0].trial_date,
              ...trial,
            };
          }
          return trial;
        })
        .sort((a, b) => {
          if (a.date && b.date) {
            return a.date.localeCompare(b.date);
          }
        }),
    ]);
  }, [userTrials]);

  React.useEffect(() => {
    // console.log(trialsList);
  }, [trialsList]);

  const deleteFileHandler = () => {
    TeacherService.deleteQualification(deleteId).then((res) => {
      setQualifications(
        qualifications.filter((item, index) => {
          return item.id != deleteId;
        })
      );
    });
    setOpen(false);
  };

  const addPrice = () => {
    const newTrialInput = {
      ...trialInput,
      id: Math.floor(Math.random() * (9999 - 1000)) + 1000,
    };
    setTrialsInput([...trialsInput, newTrialInput]);
  };

  const removePrice = (id) => {
    setTrialsInput(trialsInput.filter((price) => price.id != id));
  };

  const onPriceChangeHandler = (e, input, value = null) => {
    const newPrices = [...trialsInput];
    if (input === "duration") {
      newPrices[e.target.name] = {
        ...newPrices[e.target.name],
        duration: e.target.value,
      };
    }
    if (input === "price") {
      newPrices[e.target.name] = {
        ...newPrices[e.target.name],
        price: e.target.value,
      };
    }
    if (input === "venue") {
      newPrices[e.target.name] = {
        ...newPrices[e.target.name],
        venue: e.target.value,
      };
    }
    if (input === "slot") {
      let value = newTimeSlotRef.current;
      newPrices[e] = {
        ...newPrices[e],
        slots: [
          ...newPrices[e].slots,
          {
            teacher_id: props.profile.teacher_id,
            trial_time: getTime(value._d),
            trial_date: getFormatedDate(value._d),
          },
        ],
      };
    }
    setTrialsInput(newPrices);
  };

  const getTime = (date) => {
    var hours = date.getHours();
    hours = hours < 10 ? "0" + hours : hours;
    var minutes = date.getMinutes();
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return hours + ":" + minutes;
  };

  const getFormatedDate = (date) => {
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    month = month < 10 ? "0" + month : month;
    var day = date.getDate();
    day = day < 10 ? "0" + day : day;
    return year + "-" + month + "-" + day;
  };

  const removeSlot = (index, slotIndex) => {
    const updated = [...trialsInput];
    updated[index].slots = updated[index].slots.filter(
      (slot, ind) => ind != slotIndex
    );
    setTrialsInput(updated);
  };

  function formatDate(date) {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${date.getFullYear()}-${month > 9 ? month : "0" + month}-${
      day > 9 ? day : "0" + day
    }`;
  }

  const onHandleSlotsAdded = (data) => {
    let newLessons = [];
    data.map((value, key) => {
      const { date, slots, is_trial, teacher_id, duration } = value;
      const dateIndex = formatDate(date);
      const lessons = monthWiseLessons.map((lesson) => {
        if (lesson.date === dateIndex) {
          const newSlots = slots.map((slot) => {
            window.location.href = "/my-profile?tab=trial";
            return {
              is_trial: is_trial,
              slot_id: "0",
              teacher_id: teacher_id,
              trial_id: "0",
              trial_date: dateIndex,
              trial_time: slot.time,
              subscriber_id: "0",
              duration: slot.duration,
              seats: slot.seats,
              price: "0",
              price_id: "0",
            };
          });
          lesson.slots = [...lesson.slots, ...newSlots].sort((a, b) =>
            a.trial_time.localeCompare(b.trial_time)
          );
        }
        // else {
        //   newLessons = [...newLessons, value];
        // }
        return lesson;
      });

      setMonthWiseLessons([...lessons, ...newLessons]);
    });
  };

  useEffect(() => {
    if (currentUser) {
      TeacherService.getLessonsByTeacherId({
        teacher_id: currentUser.auth_id,
      }).then((res) => {
        setLessons(
          res.data.lessons
            .filter((lesson) => lesson.slots.length > 0)
            .map((lesson) => {
              return { ...lesson, date: lesson.slots[0].trial_date };
            })
        );
      });
    }
  }, [currentUser]);

  let val = [];
  useEffect(() => {
    if (lessons.length > 0) {
      lessons.forEach((va, index) => {
        va.slots.forEach((slot) => {
          if (val[slot.trial_date]) {
            val[slot.trial_date].push({
              ...slot,
              duration: va.duration,
              price: va.price,
              teacher_id: va.teacher_id,
              price_id: va.price_id,
            });
          } else {
            val[slot.trial_date] = [
              {
                ...slot,
                duration: va.duration,
                price: va.price,
                teacher_id: va.teacher_id,
                price_id: va.price_id,
              },
            ];
          }
        });
        setMonthWise(Object.entries(val));
      });
    }
  }, [lessons]);

  useEffect(() => {
    setMonthWiseLessons(
      monthWise.map((month) => {
        const slots = month[1].sort((a, b) => {
          return a.trial_time.localeCompare(b.trial_time);
          // return a.trial_time.localeCompare(b.trial_time);
        });
        return {
          date: month[0],
          slots: month[1],
          trial_id: month[1][0].trial_id,
        };
      })
    );
  }, [monthWise]);

  const handleRemoveActualSlot = (
    index,
    timeIndex,
    date,
    slot_id,
    type = "class"
  ) => {
    setDeleteSlot({ index, timeIndex, date, slot_id, type });
  };

  const deleteSlotHandler = (slot) => {
    const { index, timeIndex, date, slot_id, type } = slot;
    // console.log(timeIndex);
    let filterLessons = monthWiseLessons;
    const data = {
      slot_id: slot_id,
    };
    TeacherService.deleteLessonsByTeacherId(data).then((res) => {
      // console.log(res);
      const newLessons = filterLessons.map((lesson) => {
        // const { slots } = lesson;
        if (lesson.date == date) {
          // let newSlots = slots;
          // if(type == 'class')
          // newSlots.splice(timeIndex, 1);
          // else
          // newSlots.splice(index, 1);

          // // const newSlots = slots.filter((slot, ind) => {
          // //   return slot.trial_time !== time && ind !== index;
          // // });
          // lesson.slots = [...newSlots];

          setDeleteSlot(0);
        }
        window.location.reload(false);
        return lesson;
      });
      setMonthWiseLessons(newLessons);
    });
  };

  const editTimeSlot = (index, timeIndex, date, slot_id, newTime) => {
    // console.log(slot_id);
    let filterLessons = monthWiseLessons;
    const data = {
      slot_id: slot_id,
      trial_time: newTime,
    };
    TeacherService.editLessonsByTeacherId(data).then((res) => {
      // console.log(res);
      const newLessons = filterLessons.map((lesson) => {
        const { slots } = lesson;
        if (lesson.date == date) {
          let newSlots = slots;
          newSlots[timeIndex] = { ...newSlots[timeIndex], trial_time: newTime };
          // const newSlots = slots.filter((slot, ind) => {
          //   return slot.trial_time !== time && ind !== index;
          // });
          lesson.slots = [...newSlots];
          // setDeleteSlot(0);
        }
        return lesson;
      });
      setMonthWiseLessons(newLessons);
    });
  };

  const handleRemoveActualLesson = (index, date) => {
    let filterLessons = monthWiseLessons;
    const newLessons = filterLessons.filter((lesson, key) => {
      return lesson.date !== date;
    });
    setMonthWiseLessons(newLessons);
  };

  const handleSaveSchedules = (lessons) => {
    let newLesson = [];

    Object.keys(lessons).map((date) => {
      newLesson.push({
        date: date,
        slots: lessons[date].slots.map((slot) => {
          return {
            slot_id: "0",
            teacher_id: "0",
            trial_id: "0",
            trial_date: date,
            trial_time: slot,
            subscriber_id: "0",
            duration: "ddd",
            price: "555",
            price_id: "34",
          };
        }),
      });
    });
    setMonthWiseLessons([...monthWiseLessons, ...newLesson]);
    // console.log(newLesson);
    // console.log(lessons);
  };
  // const handleEditSlot = (e) => {
  //   console.log(e);
  // };

  return (
    <UserContext.Provider>
      <Mui.Grid container>
        <ConfirmationModel />
        <ConfirmationSlotModel />
        <PdfModal />
        <ImageModal />
        {/* <Progress completed={submitting} /> */}

        <Mui.Grid item xs="12" lg="12">
          <Mui.Grid container className="slide-item">
            <ClassesTabs
              handleEditSlot={(e) => setHandleEditSlot()}
              actual={
                <Mui.Grid item>
                  <div style={{ marginBottom: 20, width: "85%" }}>
                    <Calender
                      onSave={onHandleSlotsAdded}
                      user={currentUser}
                      trials={"0"}
                      title="Add new class"
                      monthWiseLessons={monthWiseLessons}
                      handleSaveSchedules={handleSaveSchedules}
                      handleEditSlot={handleEditSlot}
                    />
                  </div>

                  <ListOfTeachersLessons
                    monthWiseLessons={monthWiseLessons}
                    user={currentUser}
                    handleRemoveSlot={handleRemoveActualSlot}
                    handleRemoveLesson={handleRemoveActualLesson}
                    editActutalSlot={editTimeSlot}
                    handleEditSlot={(e) => setHandleEditSlot(e)}
                  />
                </Mui.Grid>
              }
              trials={
                <Mui.Grid item xs={12}>
                  <Mui.Box
                    style={{
                      //   border: "solid 1px gray",f
                      borderRadius: 10,
                      //   padding: 10,
                      paddingTop: 0,
                    }}
                  >
                    <div style={{ marginBottom: 20, width: "85%" }}>
                      <Calender
                        onSave={onHandleSlotsAdded}
                        user={currentUser}
                        trials={"1"}
                        title="Add new trials"
                        monthWiseLessons={monthWiseLessons}
                        handleSaveSchedules={handleSaveSchedules}
                        handleEditSlot={handleEditSlot}
                      />
                    </div>
                    <Mui.Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexWrap: "wrap",
                        columnGap: "20px",
                      }}
                    >
                      {
                        // Array.isArray(userTrials) &&
                        trialsList.map((trial) => {
                          const randomColor =
                            Colors[Math.floor(Math.random() * Colors.length)];

                          return (
                            trial.slots.length > 0 &&
                            trial.is_trial === "1" && (
                              <ListOfTeachersTrials
                                randomColor={randomColor}
                                monthWiseLessons={monthWiseLessons}
                                lessons={trial}
                                user={currentUser}
                                handleRemoveSlot={handleRemoveActualSlot}
                                handleRemoveLesson={handleRemoveActualLesson}
                                editActutalSlot={editTimeSlot}
                                handleEditSlot={(e) => setHandleEditSlot(e)}
                              />
                            )
                          );
                        })
                      }
                    </Mui.Box>
                  </Mui.Box>
                  <div style={{ display: "none" }}>
                    <Mui.Typography
                      variant="h6"
                      style={{ paddingTop: "20px", marginBottom: 0 }}
                    >
                      Add new trial
                    </Mui.Typography>
                    {trialsInput.map((trial, index) => (
                      <Mui.Grid key={trial.id} item xs={12} sm={12}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {/* <DurationInput onChangeHandler={onPriceChangeHandler} language={trial} index={index}/> */}
                          <Mui.TextField
                            ref={durationRef}
                            name={index}
                            onChange={(e) =>
                              onPriceChangeHandler(e, "duration")
                            }
                            className="form-field"
                            variant="outlined"
                            size="small"
                            placeholder="Trial name"
                            fullWidth
                            style={{ marginRight: 5 }}
                          ></Mui.TextField>
                          <Mui.TextField
                            ref={priceRef}
                            name={index}
                            onChange={(e) => onPriceChangeHandler(e, "price")}
                            className="form-field"
                            variant="outlined"
                            placeholder="Price"
                            size="small"
                            fullWidth
                          ></Mui.TextField>

                          {/* <PriceInput onChangeHandler={onPriceChangeHandler} language={trial} index={index} />    */}
                          {trialsInput.length > 1 && (
                            <div
                              style={{ marginLeft: 10 }}
                              onClick={() => removePrice(trial.id)}
                            >
                              <HighlightOffIcon />
                            </div>
                          )}
                          <Mui.TextField
                            ref={priceRef}
                            name={index}
                            onChange={(e) => onPriceChangeHandler(e, "venue")}
                            className="form-field"
                            variant="outlined"
                            placeholder="Zoom Link"
                            fullWidth
                            size="small"
                          ></Mui.TextField>
                        </div>
                        <Typography variant="body2" style={{ marginTop: 5 }}>
                          Add time slots
                        </Typography>
                        {trial.slots.length > 0 &&
                          trial.slots.map((slot, slotIndex) => (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                border: "solid 1px gray",
                                padding: 4,
                                marginBottom: 5,
                                marginTop: 5,
                              }}
                            >
                              <span>
                                {slot.trial_date} : {slot.trial_time}
                              </span>
                              <HighlightOffIcon
                                onClick={() => removeSlot(index, slotIndex)}
                              />
                            </div>
                          ))}
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <DatePicker
                            style={{ height: 30, borderRadius: 3 }}
                            ref={slotRef}
                            onOk={(e) => (newTimeSlotRef.current = e)}
                            showTime
                            name={index}
                            className="form-field"
                            placeholder="Time slot"
                          />
                          <Button
                            onClick={() => onPriceChangeHandler(index, "slot")}
                            size="small"
                            style={{
                              marginLeft: 10,
                              marginBottom: 0,
                              height: 30,
                              width: 30,
                              color: "white",
                              backgroundColor: "#6cbe87",
                              border: "none",
                              fontSize: 23,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginTop: 7,
                            }}
                          >
                            +
                          </Button>
                        </div>
                      </Mui.Grid>
                    ))}

                    <div
                      style={{
                        flex: 1,
                        paddingRight: 10,
                        color: "#1A95AC",
                        textAlign: "right",
                      }}
                    >
                      <span style={{ cursor: "pointer" }} onClick={addPrice}>
                        + Add more
                      </span>
                    </div>
                  </div>
                </Mui.Grid>
              }
            />
            <Mui.Grid item xs={12} sm={12}>
              <Mui.Grid container spacing={2}>
                {/* Trials */}

                {/* Trials End */}
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid item xs={12} sm={12} className="success-message">
          {success == true && (
            <MuiL.Alert variant="filled" severity="success">
              {responseMessage}
            </MuiL.Alert>
          )}
          {success == false && (
            <MuiL.Alert variant="filled" severity="error">
              {responseMessage}
            </MuiL.Alert>
          )}
        </Mui.Grid>
        {submitting && (
          <Mui.Grid item xs={12} sm={12}>
            {updated == false ? (
              <LinearLoader
                loaded={false}
                title="Updating profile, please wait..."
              />
            ) : (
              <LinearLoader loaded={true} title="Completed!" />
            )}
          </Mui.Grid>
        )}
        <Mui.Grid item xs={12} sm={12}>
          <Mui.Grid
            container
            justifyContent="space-between"
            className="update-btn-wrapper"
          >
            {/* <DeleteMyAccount loggedInUser={state} /> */}
            {/* {submitting ? (
              <Mui.Button
                type="button"
                disabled={true}
                className="submit-btn-disabled"
              >
                <img width="30%" src="/assets/ringloader.gif" />
                <small>Please wait..</small>
              </Mui.Button>
            ) : (
              <Mui.Button
                type="button"
                onClick={formSubmitHandler}
                className="submit-btn"
              >
                Update
              </Mui.Button>
            )} */}
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Grid>
    </UserContext.Provider>
  );
}
