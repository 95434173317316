import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Container from "@material-ui/core/Container";
import Hidden from "@material-ui/core/Hidden";
import Product from "../../services/products";
import Slider from "react-slick";
import { BrowserRouter as Router, Link } from "react-router-dom";
import SlidingProduct from "../products/slidingProduct";
import SmallProduct from "../products/SmallProduct";
import Slide from "@material-ui/core/Slide";
// import Carousel from 'react-multi-carousel';
// import 'react-multi-carousel/lib/styles.css'
import CarouselO from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Carousel } from "@trendyol-js/react-carousel";
import axios from "axios";

const pics = [
  "assets/class1.jpeg",
  "assets/class2.jpeg",
  "assets/class3.jpeg",
  "assets/class4.jpeg",
  "assets/class5.jpeg",
  "assets/class6.jpeg",
  "assets/class1.jpeg",
  "assets/class2.jpeg",
];

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  productsWrapper: {
    padding: 8,
    paddingBottom: 30,
    marginBottom: 0,
    position: "relative",
    [theme.breakpoints.up("lg")]: {
      maxWidth: "100%",
      paddingLeft: 40,
      paddingRight: 40,
      paddingBottom: 0,
      paddingTop: 0,
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      paddingBottom: 0,
    },
  },
  media: {
    height: "auto",
  },
  sectionName: {
    marginBottom: 25,
    marginTop: 30,
    fontSize: 24,
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
      marginTop: 10,
      marginBottom: 10,
    },
  },
  titleName: {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: 1.3,
  },
  singleItem: {
    height: 410,
    paddingBottom: 10,
    display: "flex",
    justifyContent: "center",
  },
  singeInner: {
    width: "90%",
    height: "100%",
    marginBottom: 20,
    marginLeft: "0%",
  },
  viewButton: {
    background: "#cee2ed",
    color: "#2a9ab2",
    textDecoration: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  link: {
    color: "#2a9ab2",
    textDecoration: "none",
  },
  authContainer: {
    backgroundColor: "#061C20",
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      paddingBottom: 20,
      paddingTop: 20,
    },
  },
  closeIcon: {
    position: "absolute",
    fontSize: 30,
    color: "white",
    right: 10,
    top: 10,
  },
  courseTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  authWrapper: {
    backgroundColor: "#fff",
    padding: 20,
    borderRadius: 15,
  },
  checkIcon: {
    width: "15%",
  },
  textCenter: {
    textAlign: "center",
  },
  checkOutModal: {},
  paperWidthXl: {
    margin: 0,
  },
  buyNowHead: {
    marginTop: 10,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  checkoutLog: {
    width: "25%",
    marginBottom: 30,
    [theme.breakpoints.down("xs")]: {
      width: "40%",
      marginBottom: 20,
    },
  },
  customButtonGroup: {
    position: "absolute",
    width: "100%",
    left: 7,
    display: "flex",
    justifyContent: "space-between",
    bottom: "40%",
    [theme.breakpoints.down("md")]: {
      left: 0,
      display: "flex",
      justifyContent: "center",
      bottom: 0,
    },
    [theme.breakpoints.down("sm")]: {
      left: 0,
      display: "flex",
      justifyContent: "center",
      bottom: -35,
    },
  },
  navButtons: {
    backgroundColor: "#00000080",
    fontSize: 30,
    borderRadius: 15,
    color: "white",
    cursor: "pointer",
    marginRight: 10,
    marginLeft: 10,
  },
}));

export default function Featured() {
  const [list, setValue] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [productsList, setProductsList] = React.useState(null);
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 3,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 1424,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 908,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: true,
          dots: true,
        },
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: true,
          dots: true,
        },
      },
    ],
  };
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 3000, min: 1920 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 1919, min: 1024 },
      items: 5,
    },
    tabletLandscape: {
      breakpoint: { max: 1024, min: 1000 },
      items: 3,
    },
    tabletLandscape: {
      breakpoint: { max: 1190, min: 1000 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 780, min: 464 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 830, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  let products = [];
  const fetchData = React.useCallback(() => {
    setTimeout(() => {
      Product.getProducts()
        .then((response) => {
          setLoading(false);
          setProductsList(response.data);
          response.data.forEach((product, index) => {
            products.push(
              <SlidingProduct
                from="none"
                key={index}
                product={product}
                index={index}
              />
            );
          });
          setValue(products);
        })
        .catch((error) => {
          console.log(error);
        });
    }, 500);

    Product.getCheck()
      .then((response) => {
        // console.log(response)
      })
      .catch((error) => {
        console.log("Some Error:", error);
      });
  }, []);

  const loadData = () => {
    var config = {
      method: "get",
      url: "https://apiendpoints.edhub.school/auth/jwt/token",
      headers: {
        Authorization: "Basic d2FsZWVkdW1lcjQyQGdtYWlsLmNvbTp0ZXN0",
      },
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const CustomLeftArrow = ({ onClick }) => (
    <i onClick={() => onClick()} className="custom-left-arrow">
      dd
    </i>
  );
  const CustomRightArrow = ({ onClick }) => {
    return (
      <Icon className={classes.customArrow} onClick={() => onClick()}>
        arrow_circle_right
      </Icon>
    );
  };
  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide },
    } = rest;
    return (
      <div className={classes.customButtonGroup}>
        <Icon className={classes.navButtons} onClick={() => previous()}>
          arrow_back
        </Icon>
        <Icon className={classes.navButtons} onClick={() => next()}>
          arrow_forward
        </Icon>
      </div>
    );
  };
  return (
    <Container className={classes.productsWrapper} maxWidth={false}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Typography
          variant="h5"
          className={classes.sectionName}
          gutterBottom
          component="h2"
        >
          Featured Products
        </Typography>
        <Button className={classes.viewButton}>
          <Link className={classes.link} to="/products/all">
            View All
          </Link>
        </Button>
      </Grid>
      {productsList != null && (
        <>
          <Hidden xsDown>
            <CarouselO
              customTransition="transform 300ms ease-in-out"
              transitionDuration={300}
              renderButtonGroupOutside={true}
              renderDotsOutside={true}
              customButtonGroup={<ButtonGroup />}
              arrows={false}
              showDots={false}
              removeArrowOnDeviceType={["mobile"]}
              responsive={responsive}
            >
              {productsList.map((product, index) => {
                return (
                  <SlidingProduct
                    from="none"
                    key={index}
                    product={product}
                    index={index}
                  />
                );
              })}
            </CarouselO>
          </Hidden>
          <Hidden smUp>
            <Grid
              className="home-products-wrapper"
              alignItems="flex-start"
              container
            >
              {loading ? (
                <h5
                  style={{
                    textAlign: "center",
                    width: "100%",
                    marginTop: 20,
                    fontSize: 20,
                  }}
                >
                  Loading...
                </h5>
              ) : productsList.length > 0 ? (
                <>
                  <Grid item xs={6}>
                    {productsList.map((item, index) => {
                      if (index % 2 === 0) {
                        return (
                          <SmallProduct
                            from={"home"}
                            product={item}
                            key={index}
                            index={index}
                          />
                        );
                      }
                    })}
                  </Grid>
                  <Grid item xs={6}>
                    {productsList.map((item, index) => {
                      if (index % 2 != 0) {
                        return (
                          <SmallProduct
                            from={"home"}
                            product={item}
                            key={index}
                            index={index}
                          />
                        );
                      }
                    })}
                  </Grid>
                </>
              ) : (
                <h5
                  style={{
                    textAlign: "center",
                    width: "100%",
                    marginTop: 20,
                    fontSize: 20,
                  }}
                >
                  No products found
                </h5>
              )}
            </Grid>
          </Hidden>
        </>
      )}
    </Container>
  );
}
