import React, {
  forwardRef,
  useState,
  useImperativeHandle,
  useContext,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Product from "../../services/products";
import SingleProduct from "../products/singleProduct";
import { UserContext } from "../../context/UserContext";
import { useJwt } from "react-jwt";
import Slide from "@material-ui/core/Slide";

const pics = [
  "assets/class1.jpeg",
  "assets/class2.jpeg",
  "assets/class3.jpeg",
  "assets/class4.jpeg",
  "assets/class5.jpeg",
  "assets/class6.jpeg",
  "assets/class1.jpeg",
  "assets/class2.jpeg",
  "assets/class1.jpeg",
  "assets/class2.jpeg",
  "assets/class3.jpeg",
  "assets/class4.jpeg",
  "assets/class5.jpeg",
  "assets/class6.jpeg",
  "assets/class1.jpeg",
  "assets/class2.jpeg",
  "assets/class1.jpeg",
  "assets/class2.jpeg",
  "assets/class3.jpeg",
  "assets/class4.jpeg",
  "assets/class5.jpeg",
  "assets/class6.jpeg",
  "assets/class1.jpeg",
  "assets/class2.jpeg",
  "assets/class1.jpeg",
  "assets/class2.jpeg",
  "assets/class3.jpeg",
  "assets/class4.jpeg",
  "assets/class5.jpeg",
  "assets/class6.jpeg",
  "assets/class1.jpeg",
  "assets/class2.jpeg",
  "assets/class1.jpeg",
  "assets/class2.jpeg",
  "assets/class3.jpeg",
  "assets/class4.jpeg",
  "assets/class5.jpeg",
  "assets/class6.jpeg",
  "assets/class1.jpeg",
  "assets/class2.jpeg",
  "assets/class1.jpeg",
  "assets/class2.jpeg",
  "assets/class3.jpeg",
  "assets/class4.jpeg",
  "assets/class5.jpeg",
  "assets/class6.jpeg",
  "assets/class1.jpeg",
  "assets/class2.jpeg",
  "assets/class1.jpeg",
  "assets/class2.jpeg",
  "assets/class3.jpeg",
  "assets/class4.jpeg",
  "assets/class5.jpeg",
  "assets/class6.jpeg",
  "assets/class1.jpeg",
  "assets/class2.jpeg",
  "assets/class1.jpeg",
  "assets/class2.jpeg",
  "assets/class3.jpeg",
  "assets/class4.jpeg",
  "assets/class5.jpeg",
  "assets/class6.jpeg",
  "assets/class1.jpeg",
  "assets/class2.jpeg",
  "assets/class1.jpeg",
  "assets/class2.jpeg",
  "assets/class3.jpeg",
  "assets/class4.jpeg",
  "assets/class5.jpeg",
  "assets/class6.jpeg",
  "assets/class1.jpeg",
  "assets/class2.jpeg",
];

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  productsWrapper: {
    padding: 0,
    marginBottom: 0,
    height: "90vh",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      height: "auto",
    },
  },
  media: {
    height: "auto",
  },
  sectionName: {
    marginBottom: 25,
    marginTop: 30,
    fontSize: 30,
    fontWeight: 700,
  },
  titleName: {
    fontSize: 15,
    fontWeight: 700,
    lineHeight: 1.1,
    fontDecoration: "none",
    color: "black",
    minHeight: 38,
    height: 38,
    overflow: "hidden",
    marginBottom: 3,
  },
  singleItem: {
    height: 320,
    paddingBottom: 10,
    display: "flex",
    justifyContent: "center",
    marginBottom: 20,
  },
  singeInner: {
    width: "95%",
    height: "100%",
    marginBottom: 20,
    [theme.breakpoints.down("sm")]: {
      marginLef: 0,
    },
  },
  productsContainerWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 40,
  },
  paginationWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  authContainer: {
    backgroundColor: "#061C20",
    height: "100vh",
  },
  closeIcon: {
    position: "absolute",
    fontSize: 30,
    color: "white",
    right: 10,
    top: 10,
  },
  courseTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  authWrapper: {
    backgroundColor: "#fff",
    padding: 20,
    borderRadius: 15,
  },
  checkIcon: {
    width: "15%",
  },
  textCenter: {
    textAlign: "center",
  },
}));

export default forwardRef(function Shop(props, ref) {
  let [list, setValue] = React.useState([]);
  let [count, setCount] = React.useState(0);
  const [totalProducts, setTotal] = React.useState(0);
  const { jwt } = React.useContext(UserContext);
  const [token, setToken] = jwt;
  const { decodedToken, isExpired } = useJwt(token);

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const [page, setPage] = React.useState(1);
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    accessibility: true,
    slidesToShow: 4,
    slidesToScroll: 3,
    arrows: true,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: true,
        },
      },
    ],
  };

  let things = useContext(0);
  let products = [];
  const fetchData = React.useCallback((offset = 0) => {
    // Product.shop(offset)
    //     .then((response) => {
    //         setCount(response.data.count)
    //         setTotal(response.data.count)
    //         response.data.products.forEach((product, index) => {
    //             products.push(
    //                 <SingleProduct from={'none'} key={index} product={product} index={index} />
    //             )
    //         })
    //         setValue(products)
    //     })
    //     .catch((error) => {
    //         console.log(error)
    //     })
    props.products.forEach((product, index) => {
      products.push(
        <SingleProduct
          from={"none"}
          key={index}
          product={product}
          index={index}
        />
      );
    });
    setValue(products);
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleChange = (event, value) => {
    setPage(value);
    products = [];

    let offset = value * 48;
    if (value == 1) offset = 0;

    Product.shop(offset)
      .then((response) => {
        // console.log(response)
        setCount(response.data.count);
        setTotal(response.data.count);
        response.data.products.forEach((product, index) => {
          products.push(
            <SingleProduct
              from={"none"}
              key={index}
              product={product}
              index={index}
            />
          );
        });
        setValue(products);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const searchShop = (title) => {
    setPage(1);
    products = [];

    Product.searchProduct(title)
      .then((response) => {
        response.data.forEach((product, index) => {
          products.push(
            <SingleProduct
              from={"none"}
              key={index}
              product={product}
              index={index}
            />
          );
        });
        setValue(products);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const filterShop = (levels, tags) => {
    console.log(levels, tags);
    setPage(1);
    products = [];
    Product.filterProduct(levels, tags)
      .then((response) => {
        console.log(response.data);
        response.data.products.forEach((product, index) => {
          products.push(
            <SingleProduct
              from={"none"}
              product={product}
              key={index}
              index={index}
            />
          );
        });
        setValue(products);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const renderProduct = (products) => {};

  const getCount = () => {
    return totalProducts;
  };

  useImperativeHandle(ref, () => ({
    handleChange,
    searchShop,
    getCount,
    filterShop,
  }));

  return (
    <Grid item xs={12} sm={9} md={10}>
      <div className={classes.searchWrapper}>
        <Container className={classes.productsWrapper} maxWidth="lg">
          <Grid container className={classes.productsContainerWrapper}>
            {list}
          </Grid>
        </Container>
      </div>
    </Grid>
  );
});
