import React, {
  forwardRef,
  useState,
  useImperativeHandle,
  useContext,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Product from "../../services/products";
import { BrowserRouter as Router, Link, useParams } from "react-router-dom";
import { CartContext } from "../../context/CartContext";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import HistoryService from "../../services/history";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { UserContext } from "../../context/UserContext";
import { FavouriteContext } from "../../context/FavouriteContext";
import { BuyNowContext } from "../../context/BuyNowContext";
import { useJwt } from "react-jwt";

const pics = [
  "assets/class1.jpeg",
  "assets/class2.jpeg",
  "assets/class3.jpeg",
  "assets/class4.jpeg",
  "assets/class5.jpeg",
  "assets/class6.jpeg",
  "assets/class1.jpeg",
  "assets/class2.jpeg",
  "assets/class1.jpeg",
  "assets/class2.jpeg",
  "assets/class3.jpeg",
  "assets/class4.jpeg",
  "assets/class5.jpeg",
  "assets/class6.jpeg",
  "assets/class1.jpeg",
  "assets/class2.jpeg",
  "assets/class1.jpeg",
  "assets/class2.jpeg",
  "assets/class3.jpeg",
  "assets/class4.jpeg",
  "assets/class5.jpeg",
  "assets/class6.jpeg",
  "assets/class1.jpeg",
  "assets/class2.jpeg",
  "assets/class1.jpeg",
  "assets/class2.jpeg",
  "assets/class3.jpeg",
  "assets/class4.jpeg",
  "assets/class5.jpeg",
  "assets/class6.jpeg",
  "assets/class1.jpeg",
  "assets/class2.jpeg",
  "assets/class1.jpeg",
  "assets/class2.jpeg",
  "assets/class3.jpeg",
  "assets/class4.jpeg",
  "assets/class5.jpeg",
  "assets/class6.jpeg",
  "assets/class1.jpeg",
  "assets/class2.jpeg",
  "assets/class1.jpeg",
  "assets/class2.jpeg",
  "assets/class3.jpeg",
  "assets/class4.jpeg",
  "assets/class5.jpeg",
  "assets/class6.jpeg",
  "assets/class1.jpeg",
  "assets/class2.jpeg",
  "assets/class1.jpeg",
  "assets/class2.jpeg",
  "assets/class3.jpeg",
  "assets/class4.jpeg",
  "assets/class5.jpeg",
  "assets/class6.jpeg",
  "assets/class1.jpeg",
  "assets/class2.jpeg",
  "assets/class1.jpeg",
  "assets/class2.jpeg",
  "assets/class3.jpeg",
  "assets/class4.jpeg",
  "assets/class5.jpeg",
  "assets/class6.jpeg",
  "assets/class1.jpeg",
  "assets/class2.jpeg",
  "assets/class1.jpeg",
  "assets/class2.jpeg",
  "assets/class3.jpeg",
  "assets/class4.jpeg",
  "assets/class5.jpeg",
  "assets/class6.jpeg",
  "assets/class1.jpeg",
  "assets/class2.jpeg",
];

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  productsWrapper: {
    padding: 30,
    marginBottom: 0,
    height: "68vh",
    overflowY: "scroll",
  },
  media: {
    height: "auto",
  },
  sectionName: {
    marginBottom: 25,
    marginTop: 30,
    fontSize: 30,
    fontWeight: 700,
  },
  titleName: {
    fontSize: 15,
    fontWeight: 700,
    lineHeight: 1.1,
    color: "black",
    [theme.breakpoints.down("sm")]: {
      height: "3.3em",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 3,
      WebkitBoxOrient: "vertical",
    },
    marginBottom: 5,
  },
  singleItem: {
    height: 365,
    [theme.breakpoints.down("sm")]: {
      height: 265,
    },
    padding: 4,
    display: "flex",
    justifyContent: "center",
    marginBottom: 5,
  },
  singeInner: {
    width: "95%",
    height: "99%",
    marginBottom: 0,
    marginLeft: "0%",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      width: "100%",
    },
    position: "relative",
  },
  paginationWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  removeBtn: {
    backgroundColor: "#d55555",
    color: "white",
    borderRadius: 5,
    padding: 6,
    height: 35,
    "&:hover": {
      backgroundColor: "#ad4242",
    },
  },

  addBtn: {
    backgroundColor: "#FA9C24",
    color: "white",
    borderRadius: 12,
    fontSize: 8,
    padding: 6,
    height: 35,
    "&:hover": {
      backgroundColor: "#a35e05",
    },
  },
  buyBtn: {
    backgroundColor: "#f16020",
    color: "white",
    borderRadius: 12,
    fontSize: 12,
    padding: 6,
    height: 35,
    "&:hover": {
      backgroundColor: "#cc501a",
    },
  },
  icon: {
    fontSize: 14,
  },
  coursePrice: {
    fontSize: 16,
    marginRight: 10,
    color: "red",
    [theme.breakpoints.down("sm")]: {
      lineHeight: "1.1",
      height: "1.1em",
    },
  },
  favouritIcon: {
    fontSize: 16,
    color: "#df3d3d",
  },
  favouriteBtn: {
    backgroundColor: "#ffffff",
    color: "#626262",
    borderRadius: 30,
    fontSize: 4,
    padding: 3,
  },
  favouriteBtnFilled: {
    backgroundColor: "#ffffff",
    color: "#df3d3d",
    borderRadius: 30,
    fontSize: 8,
    padding: 8,
  },
  subscriberName: {
    color: "#fa9c24",
    fontSize: 12,
    marginBottom: 8,
  },
  cardAction: {
    paddingTop: 3,
    paddingBottom: 0,
    paddingLeft: 5,
  },
  tag: {
    backgroundColor: "#f1f1f1",
    marginRight: 2,
    marginBottom: 2,
    fontSize: 9,
  },
  cardContent: {
    padding: 3,
    paddingBottom: 0,
  },
  cardActionInner: {
    marginBottom: 5,
    marginTop: 5,
    paddingLeft: 0,
  },
  deleteIconSingle: {
    fontSize: 20,
    color: "#fff",
    position: "absolute",
    top: 5,
    right: 5,
    zIndex: 50,
    width: 20,
    height: 20,
    backgroundColor: "#e13838",
    borderRadius: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tagsWrapper: {
    height: 50,
    overflow: "hidden",
  },
  discountedPrice: {
    textDecoration: "line-through",
    color: "gray",
    fontSize: 15,
    marginRight: 5,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#ddd",
    borderRadius: 10,
    textAlign: "center",
    width: "25%",
    boxShadow: 5,
    padding: 15,
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
  simpleButton: {
    backgroundColor: "#fff",
    marginRight: 10,
  },
  dangerButton: {
    backgroundColor: "#c23e3e",
    marginLeft: 10,
    color: "white",
  },
  centerAvatar: {
    width: 28,
    height: 28,
    borderRadius: 30,
    overflow: "hidden",
  },
  badgeAvatar: {
    width: 20,
    height: 20,
    marginLeft: 5,
  },
  ratingValue: {
    marginLeft: 6,
    color: "black",
    fontSize: 14,
  },
  ratingIcon: {
    color: "#fdd058",
    fontSize: 18,
  },
  badgeWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      bottom: "0px",
      right: "-2px",
      padding: "4px",
    },
    // top: 5,
    // left: 10
  },
  badges: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  authContainer: {
    backgroundColor: "#061C20",
    height: "100vh",
  },
  closeIcon: {
    position: "absolute",
    fontSize: 30,
    color: "white",
    right: 10,
    top: 10,
  },
  courseTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  authWrapper: {
    backgroundColor: "#fff",
    padding: 20,
    borderRadius: 15,
  },
  checkIcon: {
    width: "15%",
  },
  textCenter: {
    textAlign: "center",
  },
  floatingFavouriteBtn: {
    position: "absolute",
    top: 5,
    right: 5,
  },
}));

export default function SmallProduct(props) {
  let coverImage = null;
  // let disablePricing = true;
  const [disablePricing, setDisablePricing] = React.useState(true);
  let coverType = props.product.cover_type;
  let subscriberId = props.product.subscriber_id;
  const [openModal, setOpenModal] = React.useState(false);
  const [deleteItem, setDeleteItem] = React.useState(0);
  let courseCover =
    process.env.REACT_APP_UPLOADS_URL +
    "courses/covers/" +
    subscriberId +
    "/" +
    props.product.course_cover;
  let teacherDp = null;
  if (props.product.subscriber_id == 0) {
    courseCover =
      process.env.REACT_APP_API_URL +
      process.env.REACT_APP_TEACHER_COURSE_PATH +
      "cover/" +
      props.product.course_cover;
  }
  if (
    props.product.course_teacher != null &&
    props.product.course_teacher != ""
  ) {
    if (props.product.auth_using == "social") {
      teacherDp = props.product.profile_picture;
    } else {
      teacherDp =
        process.env.REACT_APP_API_URL +
        process.env.REACT_APP_TEACHER_DP_PATH +
        props.product.profile_picture;
    }
  }
  const classes = useStyles();
  const [cartItem, setCartItem] = useContext(CartContext);
  const { favouriteItem } = React.useContext(FavouriteContext);
  const { buy } = React.useContext(BuyNowContext);
  const { dialog } = React.useContext(BuyNowContext);
  const { auth } = React.useContext(BuyNowContext);
  const [favouriteItems, setFavouriteItems] = favouriteItem;
  const [favourites, setFavorites] = useState([]);
  const { jwt } = React.useContext(UserContext);
  const [token, setToken] = jwt;
  const { decodedToken, isExpired } = useJwt(token);
  // States from Context
  const [checkoutOpen, setCheckoutOpen] = dialog;
  const [buyProduct, setBuyProduct] = buy;
  const [isLoggedIn, setIsLoggedIn] = auth;

  const addToCart = (item) => {
    setCartItem((preItems) => [...preItems, item]);
  };
  const removeFromCart = (removeItem) => {
    setCartItem(
      cartItem.filter((item) => item.course_id != removeItem.course_id)
    );
  };
  const addToFavourite = (course) => {
    let user = localStorage.getItem("user_id");
    Product.addToFavourite(user, course.course_id).then((res) => {
      setFavouriteItems((preFavourites) => [...preFavourites, course]);
    });
  };
  const removeFromFavourite = (courseId) => {
    let user = localStorage.getItem("user_id");
    Product.removeFavourite(user, courseId).then((res) => {
      setFavouriteItems(
        favouriteItems.filter((item) => item.course_id != courseId)
      );
    });
  };

  const handleCheckoutOpen = (product) => {
    setBuyProduct(product);
    setCheckoutOpen(true);
    setIsLoggedIn(!isExpired);
  };

  var tagsObject = [];
  if (props.product.tags != null) {
    var tags = props.product.tags.split(",");
    tagsObject = tags;
  }

  function ProductRating(props) {
    return (
      <>
        <Grid container alignItems="center">
          <Icon className={classes.ratingIcon}>
            {props.rating > 0 ? "star" : "star_outline"}
          </Icon>
          <Icon className={classes.ratingIcon}>
            {props.rating > 1 ? "star" : "star_outline"}
          </Icon>
          <Icon className={classes.ratingIcon}>
            {props.rating > 2 ? "star" : "star_outline"}
          </Icon>
          <Icon className={classes.ratingIcon}>
            {props.rating > 3 ? "star" : "star_outline"}
          </Icon>
          <Icon className={classes.ratingIcon}>
            {props.rating > 4 ? "star" : "star_outline"}
          </Icon>
          <span className={classes.ratingValue}>
            {props.rating != null ? props.rating.toFixed(1) : 0}
          </span>
        </Grid>
      </>
    );
  }

  const handleItemDeletion = () => {
    setOpenModal(false);
    HistoryService.removeItemFromHistory(deleteItem).then((res) => {
      props.fetchData();
    });
  };

  const openConfirmation = (id) => {
    setOpenModal(true);
    setDeleteItem(id);
  };

  React.useEffect(() => {
    setDisablePricing(
      props.product.access_code != null && props.product.access_code != ""
        ? true
        : false
    );
    console.log(props.product.access_code);
  }, []);

  function DeleteConfirmation() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <div className={classes.paper}>
            <h3 style={{ marginBottom: 35, fontWeight: 500 }}>
              Are you sure you want to delete?
            </h3>
            <Grid>
              <Button
                className={classes.simpleButton}
                onClick={() => setOpenModal(false)}
              >
                No
              </Button>
              <Button
                className={classes.dangerButton}
                onClick={handleItemDeletion}
              >
                Yes
              </Button>
            </Grid>
          </div>
        </Fade>
      </Modal>
    );
  }

  function existInCart(product) {
    if (cartItem != null) {
      return cartItem.some(function (el) {
        return el.course_id === product;
      });
    }
    return false;
  }

  function existInFavourite(product) {
    if (favouriteItems != null) {
      return favouriteItems.some(function (el) {
        return el.course_id === product;
      });
    }
    return false;
  }

  let cardMediaType = "img";
  if (props.product.course_cover != "" && props.product.course_cover != null) {
    // courseCover = process.env.UPLOAD_URL+'courses/covers/'+subscriberId+'/'+courseCover;
    if (coverType == ".mp4") {
      cardMediaType = "video";
    } else {
      cardMediaType = "img";
    }
  } else {
    courseCover = "/" + pics[props.index];
  }
  return (
    <Grid item xs={12} lg={3} md={4} sm={6} className={classes.singleItem}>
      <div className={classes.singeInner}>
        <Card className={classes.root}>
          <CardActionArea>
            {props.history && (
              <Tooltip title="Delete item from history" placement="top">
                <Icon
                  onClick={() => openConfirmation(props.product.course_id)}
                  className={classes.deleteIconSingle}
                >
                  clear
                </Icon>
              </Tooltip>
            )}
            <Link
              onClick={() => {
                localStorage.setItem("productId", props.product.course_id);
                localStorage.setItem("productImage", courseCover);
              }}
              style={{ textDecoration: "none" }}
              to={{
                pathname:
                  props.product.access_code != null &&
                  props.product.access_code != ""
                    ? "/product/view/" +
                      props.product.course_name +
                      "/" +
                      props.product.course_id
                    : "/product/preview/" +
                      props.product.course_name +
                      "/" +
                      props.product.course_id,
                state: {
                  image: courseCover,
                  id: props.product.course_id,
                  from: props.from,
                },
              }}
            >
              <CardMedia
                component={cardMediaType}
                alt={props.product.title}
                height={"auto"}
                width="100%"
                image={courseCover}
                title={props.product.title}
              />
              <CardContent
                className={classes.cardContent}
                style={{ paddingBottom: 0 }}
              >
                <Tooltip title={props.product.course_name} placement="top">
                  <Typography
                    gutterBottom
                    className={classes.titleName}
                    variant="h6"
                    component="h3"
                  >
                    {props.product.course_name}{" "}
                  </Typography>
                </Tooltip>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid>
                    <ProductRating rating={props.product.rating} />
                  </Grid>
                  {!disablePricing && (
                    <Grid style={{ marginTop: 5, marginLeft: 3 }}>
                      {props.product.discounted_price != null &&
                      props.product.discounted_price != "" &&
                      props.product.discounted_price <
                        props.product.course_tution_fee ? (
                        <span className={classes.discountedPrice}>
                          ${props.product.course_tution_fee}
                        </span>
                      ) : (
                        <span></span>
                      )}
                      {props.product.discounted_price != null &&
                      props.product.discounted_price != "" &&
                      props.product.discounted_price <
                        props.product.course_tution_fee ? (
                        <span className={classes.coursePrice}>
                          ${props.product.discounted_price} HK
                        </span>
                      ) : (
                        <span className={classes.coursePrice}>
                          ${props.product.course_tution_fee} HK
                        </span>
                      )}
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Link>
          </CardActionArea>
          <CardActions className={classes.cardAction}>
            <Grid
              className={classes.cardActionInner}
              container
              justifyContent="space-between"
              alignItems="center"
            >
              {props.from == "home" && (
                <Grid className={classes.badgeWrapper}>
                  <div>
                    <Link to={"/shop/" + props.product.username}>
                      <Tooltip
                        title={
                          "By " +
                          (props.product.subscriber_id == 0
                            ? props.product.display_name
                            : props.product.centre_name)
                        }
                        placement="top"
                      >
                        <div className={classes.centerAvatar}>
                          {props.product.subscriber_id == 0 ? (
                            <img style={{ width: "100%" }} src={teacherDp} />
                          ) : (
                            <img
                              style={{ width: "100%" }}
                              src={
                                process.env.REACT_APP_UPLOADS_URL +
                                "admin/subscriber/" +
                                props.product.avatar
                              }
                            />
                          )}
                        </div>
                      </Tooltip>
                    </Link>
                  </div>
                  <div className={classes.badges}>
                    <Tooltip title={"Star Achiever"} placement="top">
                      <div className={classes.badgeAvatar}>
                        <img
                          style={{ width: "100%" }}
                          src="/assets/badge.png"
                        />
                      </div>
                    </Tooltip>
                    <Tooltip title={"Shield Achiever"} placement="top">
                      <div className={classes.badgeAvatar}>
                        <img
                          style={{ width: "100%" }}
                          src="/assets/shield.png"
                        />
                      </div>
                    </Tooltip>
                  </div>
                </Grid>
              )}
              <Grid>
                <Grid container alignItems="center">
                  {
                    <div className={classes.floatingFavouriteBtn}>
                      {existInFavourite(props.product.course_id) ? (
                        <Tooltip title="Remove from Favorite" placement="top">
                          <IconButton
                            className={classes.favouriteBtnFilled}
                            onClick={() =>
                              removeFromFavourite(props.product.course_id)
                            }
                            size="small"
                            color="primary"
                          >
                            <Icon className={classes.icon}>favorite</Icon>
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Add to Favorite" placement="top">
                          <IconButton
                            className={classes.favouriteBtn}
                            onClick={() => addToFavourite(props.product)}
                            size="small"
                            color="primary"
                          >
                            <Icon className={classes.favouritIcon}>
                              favorite_border
                            </Icon>
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>
                  }
                </Grid>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </div>
      <DeleteConfirmation />
    </Grid>
  );
}
