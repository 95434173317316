import React, { useEffect, useState } from "react";
import * as Mui from "@material-ui/core";
import { Box } from "@material-ui/core";
import * as MuiL from "@material-ui/lab";
import "../css/myProfile.css";
import "../css/teacherReg.css";
import TeacherService from "../../services/teacher";
import AdminService from "../../services/admin";
import { UserContext } from "../../context/UserContext";
import SwipeableViews from "react-swipeable-views";
import TagsInput from "../shared/controls/TagsInput";
import Auth from "../../services/auth";
import PdfViewer from "./modals/PdfViewer";
import ImageViewer from "./modals/ImageViewer";
import { Alert, AlertTitle } from "@material-ui/lab";
import LinearLoader from "../shared/loaders/linearLoader";
import Cropper from "react-easy-crop";
import getCroppedImg from "../helper/CroppedImage";
import ProfileCropper from "../auth/ProfileCropper";
import Followers from "./personal/Followers";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import DeleteMyAccount from "../widgets/DeleteMyAccount";
import EarningTable from "./EarningTable";
import ReactFlagsSelect from "react-flags-select";
import { DatePicker, Button, Typography } from "antd";
import "antd/dist/antd.css";
import { duration } from "moment";
import VideocamOutlinedIcon from "@material-ui/icons/VideocamOutlined";
import { Calender } from "./Lessons";
import ListOfTeachersLessons from "./Lessons/ListOfTeachersLessons";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import ListOfTeachersTrials from "./Lessons/ListOfTeachersTrials";
import Colors from "./Lessons/colors.json";
import { Tab } from "@mui/material";
import ClassesInformation from "./ClassesInformation";

function Progress(props) {
  const [loaded, setLoaded] = React.useState(0);
  const internal = setInterval(() => {
    if (loaded < 99) {
      setLoaded(loaded + 5);
      console.log(loaded);
    }
  }, 1000);

  React.useEffect(() => {
    if (props.completed) {
      setLoaded(100);
      clearInterval(internal);
    }
  }, [props.completed]);

  return <h1>{loaded}</h1>;
}

const LanguageInput = ({ language, index, onLanguageChangeHandler }) => {
  return (
    <Mui.TextField
      name={index}
      onChange={(e) => onLanguageChangeHandler(e, "language")}
      className="form-field"
      variant="outlined"
      placeholder="Language name"
      fullWidth
      size="small"
    ></Mui.TextField>
  );
};

const LevelInput = ({ language, index, onLanguageChangeHandler }) => {
  return (
    <Mui.TextField
      name={index}
      onChange={(e) => onLanguageChangeHandler(e, "level")}
      className="form-field"
      variant="outlined"
      placeholder="Level"
      fullWidth
      size="small"
    ></Mui.TextField>
  );
};

const DurationInput = ({ language, index, onChangeHandler }) => {
  return null;
};

const PriceInput = ({ language, index, onChangeHandler }) => {
  return (
    <Mui.TextField
      name={index}
      onChange={(e) => onChangeHandler(e, "price")}
      className="form-field"
      variant="outlined"
      placeholder="Price"
      fullWidth
    ></Mui.TextField>
  );
};

const DateInput = ({ language, index, onChangeHandler }) => {
  return (
    <DatePicker
      showTime
      name={index}
      className="form-field"
      placeholder="Time slot"
    />
  );
};

const Trial = ({
  trial,
  handleRemoveTrial,
  handleAddSlot,
  handleRemoveSlot,
}) => {
  const [openTooltip, setOpenTooltip] = React.useState(false);

  const [newSlot, setNewSlot] = React.useState();

  const Confirmation = () => {
    return (
      <Mui.Dialog
        open={openTooltip}
        onClose={() => setOpenTooltip(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Mui.DialogTitle id="alert-dialog-title">
          {"Delete trial price"}
        </Mui.DialogTitle>
        <Mui.DialogContent>
          <Mui.DialogContentText id="alert-dialog-description">
            Are you sure to delete
          </Mui.DialogContentText>
        </Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.Button onClick={() => setOpenTooltip(false)}>No</Mui.Button>
          <Mui.Button onClick={confirmDeletion}>Yes</Mui.Button>
        </Mui.DialogActions>
      </Mui.Dialog>
    );
  };

  const TimeSlot = ({ slot }) => {
    return (
      <Box
        style={{
          padding: 7,
          border: "1px solid gray",
          borderRadius: 5,
          marginRight: 5,
          position: "relative",
        }}
      >
        <span
          onClick={() =>
            handleRemoveSlot(slot.slot_id, slot.teacher_id, slot.trial_id)
          }
          style={{
            marginLeft: 10,
            cursor: "pointer",
            position: "absolute",
            top: 2,
            right: 2,
          }}
        >
          <HighlightOffIcon style={{ fontSize: "18px", color: "red" }} />
        </span>
        <Box
          style={{
            lineHeight: 1,
            marginRight: 30,
            fontWeight: 600,
          }}
        >
          {slot.trial_date}
        </Box>
        <Box
          style={{
            alignItems: "center",
            display: "flex",
            marginTop: 3,
          }}
        >
          <AccessTimeIcon style={{ fontSize: 16, marginRight: 4 }} />
          <small style={{ color: "gray" }}>{slot.trial_time}</small>
        </Box>
      </Box>
    );
  };

  const confirmDeletion = () => {
    handleRemoveTrial(trial.price_id, trial.teacher_id);
    setOpenTooltip(false);
  };

  const handleSlotUpdate = (value) => {
    setNewSlot(value);
  };

  const addSlot = () => {
    handleAddSlot(newSlot, trial.price_id, trial.teacher_id);
  };

  return (
    <Mui.Grid xs={12} sm={12}>
      <Confirmation />
      <div
        style={{
          marginBottom: 5,
          paddingLeft: 10,
          paddingRight: 10,
          borderRadius: 10,
          backgroundColor: "#e4f8ff",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h3 style={{ margin: 0, fontWeight: 300, color: "black" }}>
          {trial.duration}
        </h3>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Mui.Chip
            style={{
              marginRight: 10,
              color: "white",
              backgroundColor: "#82b0c0",
              borderColor: "transparent",
            }}
            label={trial.price}
            size="small"
            variant="outlined"
          ></Mui.Chip>
          <Mui.Tooltip title="Delete">
            <HighlightOffIcon
              style={{ color: "#C74344", cursor: "pointer" }}
              onClick={() => setOpenTooltip(true)}
            />
          </Mui.Tooltip>
        </div>
      </div>
      <div style={{ marginBottom: 5, display: "flex", alignItems: "center" }}>
        {trial.venue == null ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <VideocamOutlinedIcon style={{ marginRight: 5 }} />
            <small style={{ color: "#c7c7c7" }}>No video link added</small>
          </div>
        ) : (
          <Mui.Tooltip
            placement="top"
            title={
              <Mui.Typography variant="body2">
                Click to open video call link
              </Mui.Typography>
            }
          >
            <a
              style={{
                fontDecoration: "none",
                display: "flex",
                alignItems: "center",
                color: "black",
              }}
              target="_blank"
              href={trial.venue}
            >
              <VideocamOutlinedIcon style={{ marginRight: 5 }} />
              <small>{trial.venue}</small>
            </a>
          </Mui.Tooltip>
        )}
      </div>
      <div style={{ padding: 5, marginBottom: 5 }}>
        <Mui.Typography style={{ fontWeight: 600 }} variant="body2">
          Time slots
        </Mui.Typography>
        <Box
          style={{
            display: "flex",
            flexWrap: "wrap",
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          {trial.slots.map((slot, index) => (
            <TimeSlot key={index} slot={slot} />
          ))}
        </Box>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <DatePicker
            placeholder="Select slot"
            onOk={handleSlotUpdate}
            showTime
          />
          <Button onClick={addSlot} size="xs">
            Add
          </Button>
        </div>
      </div>
    </Mui.Grid>
  );
};

const Language = ({ language, handleRemoveLanguage }) => {
  const [openTooltip, setOpenTooltip] = React.useState(false);

  const Confirmation = () => {
    return (
      <Mui.Dialog
        open={openTooltip}
        onClose={() => setOpenTooltip(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Mui.DialogTitle id="alert-dialog-title">
          {"Delete language"}
        </Mui.DialogTitle>
        <Mui.DialogContent>
          <Mui.DialogContentText id="alert-dialog-description">
            Are you sure to delete
          </Mui.DialogContentText>
        </Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.Button onClick={() => setOpenTooltip(false)}>No</Mui.Button>
          <Mui.Button onClick={confirmDeletion}>Yes</Mui.Button>
        </Mui.DialogActions>
      </Mui.Dialog>
    );
  };

  const confirmDeletion = () => {
    handleRemoveLanguage(language.language_id, language.teacher_id);
    setOpenTooltip(false);
  };

  return (
    <Mui.Grid xs={12} sm={12}>
      <Confirmation />
      <div
        style={{
          marginBottom: 10,
          paddingLeft: 10,
          paddingRight: 10,
          borderRadius: 10,
          backgroundColor: "#e4f8ff",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h3 style={{ margin: 0, fontWeight: 300, color: "black" }}>
          {language.language}
        </h3>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Mui.Chip
            style={{
              marginRight: 10,
              color: "white",
              backgroundColor: "#82b0c0",
              borderColor: "transparent",
            }}
            label={language.level}
            size="small"
            variant="outlined"
          ></Mui.Chip>
          <HighlightOffIcon
            style={{ color: "#C74344", cursor: "pointer" }}
            onClick={() => setOpenTooltip(true)}
          />
        </div>
      </div>
    </Mui.Grid>
  );
};

function ProfileFields(props) {
  const [submitting, setSubmission] = React.useState(false);
  const [viewPdf, setViewPdf] = React.useState(false);
  const [fileName, setFileName] = React.useState("");
  const [viewFile, setViewFile] = React.useState(false);
  const [genderError, setGenderError] = React.useState(null);
  const [formErrors, setFormErrors] = React.useState([]);
  const { notification } = React.useContext(UserContext);
  const { user } = React.useContext(UserContext);
  const { someTest } = React.useContext(UserContext);
  const [teacherNotification, setTeacherNotification] = notification;
  const [test, setTest] = someTest;
  const [currentUser, setCurrentUser] = user;
  const [profile_picture, setProfilePicture] = React.useState(null);
  const [userLanguages, setUserLanguages] = React.useState([]);
  const [userTrials, setUserTrials] = React.useState([]);
  const [trialsList, setTrialslist] = React.useState([]);
  const [newTrials, setNewTrials] = React.useState([]);
  const [profile, setProfile] = React.useState(null);
  const [success, setSuccess] = React.useState(null);
  const [responseMessage, setMessage] = React.useState(null);
  const [filePath, setPath] = React.useState(null);
  const [qualifications, setQualifications] = React.useState([]);
  const [qualificationsNew, setNewQualifications] = React.useState([]);
  const [loaded, setLoaded] = React.useState(0);
  const [updated, setUpdated] = React.useState(false);
  const newTimeSlotRef = React.useRef();
  const durationRef = React.useRef(null);
  const priceRef = React.useRef(null);
  const slotRef = React.useRef(null);
  const [updatedSlots, setUpdatedSlots] = React.useState([]);
  // const [qualificationsNew, setNewQualifications] = React.useState(null)
  const [state, setState] = React.useState({
    first_name: "",
    last_name: "",
    country: "",
    title: "",
    sub_title: "",
    display_name: "",
    password: "",
    confirmpassword: "",
    phone_number: "",
    profile_picture: props.profile_picture,
    about: "",
    bank_name: "",
    account_holder_name: "",
    routing_number: "",
    account_number: "",
    id_number: "",
    id_doc_back: null,
    id_doc_front: null,
    user_id: null,
    username: null,
    email: null,
  });

  const languageInput = { id: 1, language: "", level: "" };
  const [languagesInput, setLanguagesInput] = React.useState([languageInput]);

  const trialInput = { id: 1, duration: "", price: "", venue: "", slots: [] };
  const [trialsInput, setTrialsInput] = React.useState([trialInput]);

  const style = {
    position: "absolute",
    top: "40%",
    left: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "10",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const [open, setOpen] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(false);

  const handleOpen = (id) => {
    setOpen(true);
    setDeleteId(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const ConfirmationModel = (id) => {
    return (
      <div>
        <Mui.Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Mui.Box sx={{ ...style, width: 300, borderRadius: 10 }}>
            <h1 style={{ marginBottom: 20, fontSize: 20 }}>Are you Sure?</h1>
            <div>
              <Mui.Button onClick={handleClose} className="no-btn">
                No
              </Mui.Button>
              <Mui.Button
                className="yes-btn"
                onClick={() => deleteFileHandler()}
              >
                Yes
              </Mui.Button>
            </div>
          </Mui.Box>
        </Mui.Modal>
      </div>
    );
  };

  const PdfModal = () => {
    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "70%",
      height: "95vh",
      bgcolor: "background.paper",
      border: "2px solid #E1E1E1",
      borderRadius: 15,
      boxShadow: 24,
      p: 4,
    };
    return (
      // <div>
      <Mui.Modal
        open={viewPdf}
        onClose={() => handleFileView(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Mui.Box sx={style}>
          <Mui.Icon
            onClick={() => handleFileView(false)}
            className="modal-close-btn"
          >
            close
          </Mui.Icon>
          <h2 class="parent-modal-title">{fileName}</h2>
          <PdfViewer style={{ overflowY: "scroll" }} url={filePath} />
        </Mui.Box>
      </Mui.Modal>
      // </div>
    );
  };

  const ImageModal = () => {
    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "70%",
      height: "95vh",
      bgcolor: "background.paper",
      border: "2px solid #E1E1E1",
      borderRadius: 15,
      boxShadow: 24,
      overflowY: "scroll",
      overflowX: "hidden",
      p: 4,
    };
    return (
      // <div>
      <Mui.Modal
        open={viewFile}
        onClose={() => handleFileView(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Mui.Box sx={style}>
          <Mui.Icon
            onClick={() => handleFileView(false)}
            className="modal-close-btn"
          >
            close
          </Mui.Icon>
          <h2 class="parent-modal-title">{fileName}</h2>
          <ImageViewer style={{ overflowY: "scroll" }} url={filePath} />
        </Mui.Box>
      </Mui.Modal>
      // </div>
    );
  };

  const handleFileView = (show, path = null, type = null, name = null) => {
    if (type == "pdf") {
      setPath(path);
      setFileName(name);
      setViewPdf(show);
    }
    if (type == null) {
      setViewPdf(false);
      setViewFile(false);
    }
    if (type == "jpg" || type == "png" || type == "jpeg") {
      setPath(path);
      setFileName(name);
      setViewFile(show);
    }
  };

  React.useEffect(() => {
    console.log(props.profile);
    if (props.profile != null) {
      setQualifications(props.profile.qualifications);
      setUserLanguages(props.profile.languages);

      if (Array.isArray(props.profile.trial_prices))
        setUserTrials(props.profile.trial_prices);

      setLoaded(1);
      setState({
        ...state,
        first_name: props.profile.user_details.first_name,
        title: props.profile.title,
        sub_title: props.profile.sub_title,
        country: props.profile.country,
        user_id: props.profile.user_id,
        username: props.profile.user.username,
        email: props.profile.user.email,
        last_name: props.profile.user_details.last_name,
        display_name: props.profile.user_details.display_name,
        phone_number: props.profile.phone_number,
        about: props.profile.about,
        bank_name:
          props.profile.bank_details != null
            ? props.profile.bank_details.bank_name
            : null,
        account_holder_name:
          props.profile.bank_details != null
            ? props.profile.bank_details.account_holder_name
            : null,
        routing_number:
          props.profile.bank_details != null
            ? props.profile.bank_details.routing_number
            : null,
        account_number:
          props.profile.bank_details != null
            ? props.profile.bank_details.account_number
            : null,
        stripe_document_status:
          props.profile.bank_details != null
            ? props.profile.bank_details.stripe_document_status
            : null,
        id_number: null,
        stripe_connect_id:
          props.profile.user.stripe_connect_id == null
            ? "null"
            : props.profile.user.stripe_connect_id,
      });
    }
  }, [props.profile]);

  const onChangeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setState({
      ...state,
      [name]: value,
    });
  };

  const fileIcons = {
    pdf: "/assets/icons/pdf.png",
    jpeg: "/assets/icons/image.png",
    png: "/assets/icons/image.png",
    jpg: "/assets/icons/image.png",
    doc: "/assets/icons/doc.png",
    docx: "/assets/icons/doc.png",
  };

  const deleteFileHandler = () => {
    TeacherService.deleteQualification(deleteId).then((res) => {
      setQualifications(
        qualifications.filter((item, index) => {
          return item.id != deleteId;
        })
      );
      setOpen(false);
    });
  };

  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        profile_picture,
        croppedAreaPixels,
        0
      ).then((res) => {
        return res;
      });
    } catch (e) {
      console.error(e);
    }
  };

  const formSubmitHandler = (event) => {
    let formValues = state;
    formValues["routing_number"] =
      state.routing_number != null ? state.routing_number.trim() : "";
    formValues["account_number"] =
      state.account_number != null ? state.account_number.trim() : "";
    setState(formValues);
    setSubmission(true);
    var data = state;
    data["auth_id"] = localStorage.getItem("auth_id");
    data["user_id"] = localStorage.getItem("user_id");
    data["bank_email"] = props.profile.user.email;
    // data['stripe_connect_id'] = props.user.stripe_connect_id == null ? 'null' : props.user.stripe_connect_id
    data["languages"] = JSON.stringify(languagesInput);
    data["trials"] = JSON.stringify(trialsInput);
    const formD = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (key != "profile_picture") {
        formD.append(key, value);
      } else {
        if (props.newProfilePictureBlob != null)
          formD.append(
            key,
            new File([props.newProfilePictureBlob], "fileName.jpeg", {
              type: "image/jpeg",
              lastModified: new Date(),
            })
          );
      }
    });
    if (qualificationsNew.length > 0) {
      qualificationsNew.map((files) => {
        for (let index = 0; index < files.length; index++) {
          formD.append("qualification_files[]", files[index]);
        }
      });
    }
    if (qualificationsNew != null) {
      for (let index = 0; index < qualificationsNew.length; index++) {
        formD.append("qualification_files[]", qualificationsNew[index]);
      }
    }

    formD.append("updated_slots", JSON.stringify(updatedSlots));

    TeacherService.updateTeacherById(formD).then(
      (res) => {
        console.log(res);
        setUpdated(true);
        setTimeout(() => {
          setSubmission(false);
          setSuccess(!res.data.error);
          if (res.data.messages == "Validation error") {
            let errors = "";
            Object.entries(res.data.errors).forEach(
              ([key, value]) => (errors += value + ". ")
            );
            console.log(res.data.errors);
            setMessage(errors);
          } else {
            setMessage(res.data.messages);
          }
          setTeacherNotification(false);
          setUpdated(false);

          setUserTrials([...userTrials, ...trialsInput]);
          setTimeout(() => {
            setSuccess(null);
            setMessage(null);
            setTrialsInput([trialInput]);
            durationRef.current.value = "";
            priceRef.current.value = "";
            slotRef.current.value = "";
            // let user = currentUser;
            // user.routing_number = formValues['routing_number']
            // user.account_number = formValues['account_number']
            // user.bank_name = state.bank_name
            // user.account_holder_name = state.account_holder_name
            // user.stripe_connect_id = '0';
            // setCurrentUser(user)
            // setTest('Some')
          }, 5000);
        }, 1200);
      },
      (err) => {
        console.log(err);
        setSubmission(false);
        setSuccess(false);
        setMessage("Error occured");
        setTeacherNotification(false);
        setTimeout(() => {
          setSuccess(null);
          setMessage(null);
        }, 10000);
      }
    );
  };

  const handleQualificationChange = (event) => {
    setNewQualifications((prev) => [...prev, event.target.files]);
    props.handleProfileUpdate(event.target.files);
  };

  const handleOnChangeIdDoc = (event, place) => {
    setState({
      ...state,
      [`id_doc_${place}`]: event.target.files[0],
    });
  };

  const addLanguage = () => {
    const newLanguageInput = {
      ...languageInput,
      id: Math.floor(Math.random() * (9999 - 1000)) + 1000,
    };
    setLanguagesInput([...languagesInput, newLanguageInput]);
  };

  const removeLanguage = (id) => {
    setLanguagesInput(languagesInput.filter((language) => language.id != id));
  };

  const addPrice = () => {
    const newTrialInput = {
      ...trialInput,
      id: Math.floor(Math.random() * (9999 - 1000)) + 1000,
    };
    setTrialsInput([...trialsInput, newTrialInput]);
  };

  const removePrice = (id) => {
    setTrialsInput(trialsInput.filter((price) => price.id != id));
  };

  const onLanguageChangeHandler = (e, input) => {
    const newLanguage = [...languagesInput];
    if (input === "language") {
      newLanguage[e.target.name] = {
        ...newLanguage[e.target.name],
        language: e.target.value,
      };
    }
    if (input === "level") {
      newLanguage[e.target.name] = {
        ...newLanguage[e.target.name],
        level: e.target.value,
      };
    }
    setLanguagesInput(newLanguage);
  };

  const onPriceChangeHandler = (e, input, value = null) => {
    const newPrices = [...trialsInput];
    if (input === "duration") {
      newPrices[e.target.name] = {
        ...newPrices[e.target.name],
        duration: e.target.value,
      };
    }
    if (input === "price") {
      newPrices[e.target.name] = {
        ...newPrices[e.target.name],
        price: e.target.value,
      };
    }
    if (input === "venue") {
      newPrices[e.target.name] = {
        ...newPrices[e.target.name],
        venue: e.target.value,
      };
    }
    if (input === "slot") {
      let value = newTimeSlotRef.current;
      newPrices[e] = {
        ...newPrices[e],
        slots: [
          ...newPrices[e].slots,
          {
            teacher_id: props.profile.teacher_id,
            trial_time: getTime(value._d),
            trial_date: getFormatedDate(value._d),
          },
        ],
      };
    }
    setTrialsInput(newPrices);
  };

  const getTime = (date) => {
    var hours = date.getHours();
    hours = hours < 10 ? "0" + hours : hours;
    var minutes = date.getMinutes();
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return hours + ":" + minutes;
  };

  const getFormatedDate = (date) => {
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    month = month < 10 ? "0" + month : month;
    var day = date.getDate();
    day = day < 10 ? "0" + day : day;
    return year + "-" + month + "-" + day;
  };

  const handleRemoveTrial = (trialId, teacherId) => {
    TeacherService.deleteTrialPrice(trialId, teacherId).then((res) => {
      setUserTrials(userTrials.filter((trial) => trial.price_id != trialId));
      return true;
    });
  };

  const handleRemoveLanguage = (languageId, teacherId) => {
    TeacherService.deleteLanguage(languageId, teacherId).then((res) => {
      setUserLanguages(
        userLanguages?.filter((language) => language.language_id != languageId)
      );
      return true;
    });
  };

  const handleCountryChange = (code) => {
    setState({ ...state, country: code });
  };

  const handleAddSlot = (slot, trialId, teacherId) => {
    let index = userTrials.findIndex((trial) => trial.price_id == trialId);
    const updatedTrials = [...userTrials];
    let newSlot = {
      trial_id: trialId,
      trial_time: getTime(slot._d),
      trial_date: getFormatedDate(slot._d),
      teacher_id: teacherId,
    };
    setUpdatedSlots([...updatedSlots, newSlot]);
    updatedTrials[index].slots = [...updatedTrials[index].slots, newSlot];
    setUserTrials(updatedTrials);
  };

  const handleRemoveSlot = (slotId, teacher, trialId) => {
    if (slotId) {
      TeacherService.deleteTimeSlot(slotId, teacher, trialId).then((res) => {
        const trialIndex = userTrials.findIndex(
          (trial) => trial.price_id === trialId.toString()
        );
        const allTrials = [...userTrials];
        allTrials[trialIndex].slots = allTrials[trialIndex].slots.filter(
          (slot) => slot.slot_id != slotId
        );
        setUserTrials(allTrials);
      });
    } else {
      const trialIndex = userTrials.findIndex(
        (trial) => trial.price_id === trialId.toString()
      );
      const allTrials = [...userTrials];
      allTrials[trialIndex].slots = allTrials[trialIndex].slots.filter(
        (slot) => slot.slot_id != slotId
      );
      setUserTrials(allTrials);
      setUpdatedSlots(updatedSlots.filter((slot) => slot.trial_id != trialId));
    }
  };

  const removeSlot = (index, slotIndex) => {
    const updated = [...trialsInput];
    updated[index].slots = updated[index].slots.filter(
      (slot, ind) => ind != slotIndex
    );
    setTrialsInput(updated);
  };

  return (
    <UserContext.Provider>
      <Mui.Grid container>
        <ConfirmationModel />
        <PdfModal />
        <ImageModal />
        {/* <Progress completed={submitting} /> */}
        <Mui.Grid item xs="12" lg="7">
          <Mui.Grid container className="slide-item">
            <Mui.Grid item xs={12} sm={12}>
              <Mui.Grid container spacing={2}>
                <Mui.Grid item xs={12} sm={12} className="tag-header">
                  <Mui.Typography className="section-heading" variant="body1">
                    Basic Information
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid item xs={12} sm={6}>
                  <label className="form-label">First Name</label>
                  <Mui.TextField
                    name="first_name"
                    onChange={onChangeHandler}
                    className="form-field"
                    required
                    value={state.first_name}
                    variant="outlined"
                    placeholder="First Name"
                    fullWidth
                  ></Mui.TextField>
                </Mui.Grid>
                <Mui.Grid item xs={12} sm={6}>
                  <label>Last Name</label>
                  <Mui.TextField
                    name="last_name"
                    onChange={onChangeHandler}
                    className="form-field"
                    required
                    value={state.last_name}
                    variant="outlined"
                    placeholder="Last Name"
                    fullWidth
                  ></Mui.TextField>
                </Mui.Grid>
                <Mui.Grid item xs={12} sm={6}>
                  <label>Display Name</label>
                  <Mui.TextField
                    name="display_name"
                    onChange={onChangeHandler}
                    className="form-field"
                    required
                    value={state.display_name}
                    variant="outlined"
                    placeholder="Display Name"
                    fullWidth
                  ></Mui.TextField>
                </Mui.Grid>
                <Mui.Grid item xs={12} sm={6}>
                  <label>Country</label>
                  <ReactFlagsSelect
                    className="form-field country-selector"
                    selected={state.country}
                    searchable
                    onSelect={(code) => handleCountryChange(code)}
                  />
                </Mui.Grid>
                <Mui.Grid item xs={12} sm={12}>
                  <label>Title</label>
                  <Mui.TextField
                    name="title"
                    onChange={onChangeHandler}
                    className="form-field"
                    required
                    value={state.title}
                    variant="outlined"
                    placeholder="Title"
                    fullWidth
                  ></Mui.TextField>
                </Mui.Grid>
                <Mui.Grid item xs={12} sm={12}>
                  <label>Tag line</label>
                  <Mui.TextField
                    name="sub_title"
                    onChange={onChangeHandler}
                    className="form-field"
                    required
                    value={state.sub_title}
                    variant="outlined"
                    placeholder="Tag line"
                    fullWidth
                  ></Mui.TextField>
                </Mui.Grid>
                <Mui.Grid item xs={12} sm={6}>
                  <label>Phone Number</label>
                  <Mui.TextField
                    name="phone_number"
                    onChange={onChangeHandler}
                    className="form-field"
                    required
                    value={state.phone_number}
                    variant="outlined"
                    placeholder="Phone Number"
                    fullWidth
                  ></Mui.TextField>
                </Mui.Grid>
                <Mui.Grid item xs={12} sm={6}>
                  <label>Email</label>
                  {props.profile != null ? (
                    props.profile.user.email == null ||
                    props.profile.user.email == "" ? (
                      <Mui.TextField
                        className="form-field"
                        value={
                          props.profile != null && props.profile.user.email
                        }
                        variant="outlined"
                        placeholder="Email"
                        fullWidth
                      ></Mui.TextField>
                    ) : (
                      <Mui.TextField
                        className="form-field"
                        disabled
                        value={
                          props.profile != null && props.profile.user.email
                        }
                        variant="outlined"
                        placeholder="Email"
                        fullWidth
                      ></Mui.TextField>
                    )
                  ) : (
                    ""
                  )}
                </Mui.Grid>
                <Mui.Grid item xs={12} sm={6}>
                  <label>Username</label>
                  <Mui.TextField
                    className="form-field"
                    disabled
                    value={props.profile != null && props.profile.user.username}
                    variant="outlined"
                    placeholder="Username"
                    fullWidth
                  ></Mui.TextField>
                </Mui.Grid>
                {/* Banking Information */}
                {props.profile != null && (
                  <>
                    <Mui.Grid item xs={12} sm={12} className="tag-header">
                      <Mui.Typography variant="body1">
                        Banking Information
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.TextField
                      style={{ display: "none" }}
                      value={state.stripe_connect_id}
                      name="stripe_connect_id"
                      onChange={onChangeHandler}
                      className="form-field"
                      required
                      variant="outlined"
                      placeholder="stripe_account_id"
                      type="text"
                      fullWidth
                    ></Mui.TextField>
                    <Mui.Grid item xs={12} sm={6}>
                      <label>Bank name</label>
                      <Mui.TextField
                        name="bank_name"
                        value={state.bank_name}
                        onChange={onChangeHandler}
                        className="form-field"
                        required
                        variant="outlined"
                        placeholder="HSBC"
                        type="text"
                        fullWidth
                      ></Mui.TextField>
                    </Mui.Grid>
                    <Mui.Grid item xs={12} sm={6}>
                      <label>Account holder name</label>
                      <Mui.TextField
                        name="account_holder_name"
                        value={state.account_holder_name}
                        onChange={onChangeHandler}
                        className="form-field"
                        required
                        variant="outlined"
                        placeholder="Mr. XYZ"
                        type="text"
                        fullWidth
                      ></Mui.TextField>
                    </Mui.Grid>
                    <Mui.Grid item xs={12} sm={6}>
                      <label>Routing number</label>
                      <Mui.TextField
                        name="routing_number"
                        value={state.routing_number}
                        onChange={onChangeHandler}
                        className="form-field"
                        required
                        variant="outlined"
                        placeholder="123-********"
                        type="text"
                        fullWidth
                      ></Mui.TextField>
                    </Mui.Grid>
                    <Mui.Grid item xs={12} sm={6}>
                      <label>Account number</label>
                      <Mui.TextField
                        name="account_number"
                        value={state.account_number}
                        onChange={onChangeHandler}
                        className="form-field"
                        required
                        variant="outlined"
                        placeholder="123456789"
                        type="text"
                        fullWidth
                      ></Mui.TextField>
                    </Mui.Grid>
                    <Mui.Grid item xs={12} sm={12}>
                      <label>ID Number</label>
                      <Mui.TextField
                        name="id_number"
                        value={state.id_number}
                        onChange={onChangeHandler}
                        className="form-field"
                        required
                        variant="outlined"
                        placeholder="AS9087(E)"
                        type="text"
                        fullWidth
                      ></Mui.TextField>
                    </Mui.Grid>
                    {state.stripe_document_status != "provided" && (
                      <>
                        <Mui.Grid item xs={12} sm={6}>
                          <label>ID Document (Front)</label>
                          <ImageUploader
                            place={"front"}
                            image={state.id_doc_front}
                            id="id-doc-front"
                            onChange={handleOnChangeIdDoc}
                            toolTipTitle="Click to upload image"
                          />
                        </Mui.Grid>
                        <Mui.Grid item xs={12} sm={6}>
                          <label>ID Document (Back)</label>
                          <ImageUploader
                            place={"back"}
                            image={state.id_doc_back}
                            id="id-doc-back"
                            onChange={handleOnChangeIdDoc}
                            toolTipTitle="Click to upload image"
                          />
                        </Mui.Grid>
                      </>
                    )}
                  </>
                )}

                {/* Account & Security */}
                <Mui.Grid item xs={12} sm={12} className="tag-header">
                  <Mui.Typography variant="body1">
                    Account & Security
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid item xs={12} sm={6}>
                  <label>New Password</label>
                  <Mui.TextField
                    inputProps={{ autoComplete: "new-password" }}
                    name="password"
                    onChange={onChangeHandler}
                    className="form-field"
                    required
                    variant="outlined"
                    placeholder="New Password"
                    type="password"
                    fullWidth
                  ></Mui.TextField>
                </Mui.Grid>
                <Mui.Grid item xs={12} sm={6}>
                  <label>Confirm Password</label>
                  <Mui.TextField
                    name="confirmpassword"
                    onChange={onChangeHandler}
                    className="form-field"
                    required
                    variant="outlined"
                    placeholder="Confirm New Password"
                    type="password"
                    fullWidth
                  ></Mui.TextField>
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid item xs="12" lg="5">
          <Mui.Grid container className="slide-item">
            <Mui.Grid item xs={12} sm={12}>
              <Mui.Grid container spacing={2}>
                {/* Other */}
                <Mui.Grid item xs={12} sm={12} className="tag-header">
                  <Mui.Typography variant="body1">Other</Mui.Typography>
                </Mui.Grid>
                <Mui.Grid item className="about" xs={12} sm={12}>
                  <label>About</label>
                  <Mui.TextField
                    name="about"
                    onChange={onChangeHandler}
                    className="form-field"
                    value={state.about}
                    multiline
                    rows={8}
                    variant="outlined"
                    placeholder="About (Brief introduction about yourself)"
                    fullWidth
                  ></Mui.TextField>
                </Mui.Grid>

                {/* Qualification */}
                <Mui.Grid item xs={12} sm={12} className="tag-header">
                  <Mui.Typography variant="body1">Qualification</Mui.Typography>
                </Mui.Grid>
                {qualifications.length > 0 && (
                  <Mui.Grid item xs={12} sm={12}>
                    <div className="file-header-wrapper">
                      <div className="name-header-wrapper">
                        <span>My Selected Files</span>
                      </div>
                    </div>
                  </Mui.Grid>
                )}
                <Mui.Grid item xs={12} sm={12}>
                  {qualifications.length > 0 ? (
                    qualifications.map((item, index) => {
                      return (
                        <div className="file-wrapper">
                          <img
                            src={
                              fileIcons[
                                item.title.substr(
                                  item.title.lastIndexOf(".") + 1
                                )
                              ]
                            }
                          />
                          <div className="name-wrapper">
                            <span>{item.title}</span>
                            <Mui.Tooltip
                              placement="top"
                              title="Delete Attachment"
                            >
                              <Mui.Icon
                                onClick={() => handleOpen(item.id)}
                                className="delete-icon"
                              >
                                close
                              </Mui.Icon>
                            </Mui.Tooltip>
                          </div>
                          <Mui.Tooltip placement="top" title="View Attachment">
                            <Mui.Icon
                              onClick={() =>
                                handleFileView(
                                  true,
                                  item.doc_path,
                                  item.doc_type,
                                  item.title
                                )
                              }
                              className="view-icon"
                            >
                              visibility
                            </Mui.Icon>
                          </Mui.Tooltip>
                        </div>
                      );
                    })
                  ) : (
                    <Mui.Typography
                      variant="body1"
                      style={{
                        textAlign: "center",
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    >
                      No document found
                    </Mui.Typography>
                  )}
                </Mui.Grid>

                <Mui.Grid item xs={12} sm={12}>
                  <input
                    accept="image/*,.pdf"
                    multiple
                    id="upload-documents"
                    onChange={handleQualificationChange}
                    type="file"
                    hidden
                  />
                  <label htmlFor="upload-documents">
                    <Mui.Button
                      ref={props.uploadButton}
                      component="span"
                      className="new-document-button"
                    >
                      <div className="qualification-inner">
                        <Mui.Icon className="upload-icon">backup</Mui.Icon>
                        <p>Upload New Document</p>
                      </div>
                    </Mui.Button>
                  </label>
                </Mui.Grid>
                {/* <Mui.Grid item className="qualification" xs={12} sm={6}>
                                        <Mui.TextField className="form-field" required variant="outlined" placeholder="Qualification" fullWidth></Mui.TextField>
                                    </Mui.Grid> */}

                <Mui.Grid item xs={12} sm={12}>
                  {formErrors.map((error, index) => {
                    return (
                      <Mui.Typography
                        key={index}
                        className="errors"
                        variant="body2"
                      >
                        {error}
                      </Mui.Typography>
                    );
                  })}
                </Mui.Grid>
                {/* Qualification end */}
                {/* Languages */}
                <Mui.Grid item xs={12} sm={12}>
                  <Mui.Typography className="tag-header" variant="body1">
                    Languages
                  </Mui.Typography>
                  {userLanguages?.map((language) => (
                    <Language
                      key={language.language_id}
                      handleRemoveLanguage={handleRemoveLanguage}
                      language={language}
                    />
                  ))}
                  <Mui.Typography style={{ paddingTop: "10px" }} variant="h6">
                    Add new language
                  </Mui.Typography>
                  {languagesInput.map((language, index) => (
                    <Mui.Grid key={language.id} item xs={12} sm={12}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Mui.Box style={{ marginRight: 5, width: "50%" }}>
                          <LanguageInput
                            onLanguageChangeHandler={onLanguageChangeHandler}
                            language={language}
                            index={index}
                          />
                        </Mui.Box>
                        <Mui.Box style={{ width: "50%" }}>
                          <LevelInput
                            onLanguageChangeHandler={onLanguageChangeHandler}
                            language={language}
                            index={index}
                          />
                        </Mui.Box>
                        {languagesInput.length > 1 && (
                          <div
                            style={{ marginLeft: 10 }}
                            onClick={() => removeLanguage(language.id)}
                          >
                            <HighlightOffIcon />
                          </div>
                        )}
                      </div>
                    </Mui.Grid>
                  ))}
                  <div
                    style={{
                      flex: 1,
                      paddingRight: 10,
                      color: "#1A95AC",
                      textAlign: "right",
                    }}
                  >
                    <span style={{ cursor: "pointer" }} onClick={addLanguage}>
                      + Add more
                    </span>
                  </div>
                </Mui.Grid>
                {/* Language End */}
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid item xs={12} sm={12} className="success-message">
          {success == true && (
            <MuiL.Alert variant="filled" severity="success">
              {responseMessage}
            </MuiL.Alert>
          )}
          {success == false && (
            <MuiL.Alert variant="filled" severity="error">
              {responseMessage}
            </MuiL.Alert>
          )}
        </Mui.Grid>
        {submitting && (
          <Mui.Grid item xs={12} sm={12}>
            {updated == false ? (
              <LinearLoader
                loaded={false}
                title="Updating profile, please wait..."
              />
            ) : (
              <LinearLoader loaded={true} title="Completed!" />
            )}
          </Mui.Grid>
        )}
      </Mui.Grid>
      <Mui.Grid item xs={12} sm={12}>
        <Mui.Grid
          container
          justifyContent="space-between"
          className="update-btn-wrapper"
        >
          <DeleteMyAccount loggedInUser={state} />
          {submitting ? (
            <Mui.Button
              type="button"
              disabled={true}
              className="submit-btn-disabled"
            >
              <img width="30%" src="/assets/ringloader.gif" />
              <small>Please wait..</small>
            </Mui.Button>
          ) : (
            <Mui.Button
              type="button"
              onClick={formSubmitHandler}
              className="submit-btn"
            >
              Update
            </Mui.Button>
          )}
        </Mui.Grid>
      </Mui.Grid>
    </UserContext.Provider>
  );
}

const ImageUploader = ({ toolTipTitle, image, onChange, place, id }) => {
  return (
    <div className="image-uploader">
      <input
        accept="image/*"
        id={id}
        onChange={(e) => onChange(e, place)}
        type="file"
        hidden
      />
      <label htmlFor={id}>
        <Mui.Tooltip placement="bottom" title={toolTipTitle}>
          <Mui.Button component="span" className="upload-picture-button">
            {image != null ? (
              <img src={URL.createObjectURL(image)} width="100%" />
            ) : (
              <Mui.Icon className="picture-icon">photo_camera</Mui.Icon>
            )}
          </Mui.Button>
        </Mui.Tooltip>
      </label>
    </div>
  );
};

let croppedAreaPixels = null;
export default function MyProfile() {
  const uploadButton = React.useRef(null);
  const [value, setValue] = React.useState(0);
  const [profile, setProfile] = React.useState(null);
  const { user } = React.useContext(UserContext);
  const [profile_picture, setProfilePicture] = React.useState(null);
  const [gender, setGender] = React.useState(null);
  const [about, setAbout] = React.useState(null);
  const [success, setSuccess] = React.useState(false);
  const [pictureChanged, setPictureChanged] = React.useState(false);
  const [adminRequest, setAdminRequest] = React.useState(null);
  const [showProfileCropper, setShowProfileCropper] = React.useState(false);
  const formRef = React.useRef(null);
  const [currentUser, setCurrentUser] = user;
  const { notification } = React.useContext(UserContext);
  const [teacherNotification, setTeacherNotification] = notification;
  const [blobImage, setBlobImage] = React.useState(null);
  const [showCropper, setShowCropper] = React.useState(true);
  const [subTabIndex, setSubTabIndex] = React.useState(0);
  const [newProfilePicture, setNewProfilePicture] = React.useState(null);
  const [newProfilePictureBlob, setNewProfilePictureBlob] =
    React.useState(null);
  const [crop, setCrop] = React.useState({
    x: 0,
    y: 0,
  });
  const [zoom, setZoom] = React.useState(1);

  // const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(null)
  const [croppedImage, setCroppedImage] = React.useState(null);
  // const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(null)

  const onCropComplete = React.useCallback(
    (croppedArea, croppedAreaPixelsValue) => {
      croppedAreaPixels = croppedAreaPixelsValue;
    },
    []
  );
  const setCroppedAreaPixels = (value) => {
    croppedAreaPixels = value;
  };

  // const saveProfilePicture = () => {
  //     showCroppedImage().then(res => {
  //         console.log(res)
  //     })
  //     // setProfilePicture()
  // }

  const [state, setState] = React.useState({
    first_name: "",
    title: "",
    sub_title: "",
    user_id: "",
    last_name: "",
    country: "",
    display_name: "",
    email: "",
    username: "",
    password: "",
    confirmpassword: "",
    phone_number: "",
    about: "",
    gender: "",
    qualification: "",
    interest: "",
  });

  const handleChange = (event, newValue) => {
    console.log(value);
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const getAdminRequest = () => {
    AdminService.getTeacherDocumentRequest(
      localStorage.getItem("auth_id")
    ).then((res) => {
      if (res.data.length > 0) {
        setAdminRequest(res.data);
      } else {
        setAdminRequest(null);
      }
    });
  };

  const handleSelecetedTags = (items) => {
    // setInterest(items)
  };

  const handleImageChange = (event) => {
    setProfilePicture(event.target.files[0]);
    setPictureChanged(true);
  };

  React.useEffect(() => {
    TeacherService.getUserById(localStorage.getItem("auth_id")).then((res) => {
      console.log(res);
      setProfile(res.data);
      if (res.data.user_details.auth_using == "social") {
        setProfilePicture(res.data.user_details.profile_picture);
      } else {
        setProfilePicture(res.data.profile_picture);
      }
    });
    if (teacherNotification) {
      getAdminRequest();
    }
  }, []);

  const handleProfileUpdate = (files) => {
    let currentProfile = profile;
    for (let index = 0; index < files.length; index++) {
      let newFile = {
        id: files[index].name,
        title: files[index].name,
        teacher_id: "0",
        subscriber_id: "0",
        doc_name: files[index].name,
        doc_type: "png",
        doc_path: URL.createObjectURL(files[index]),
      };
      currentProfile.qualifications.push(newFile);
    }
    setProfile(currentProfile);
    console.log(currentProfile.qualifications);
  };

  const onChangeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setState({
      ...state,
      [name]: value,
    });
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Mui.Box sx={{ p: 3 }}>
            <Mui.Typography>{children}</Mui.Typography>
          </Mui.Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  const Heading = (props) => {
    const [value, setValue] = useState();

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    return (
      <>
        <Mui.Grid container justifyContent="center" alignItems="center">
          <Mui.Icon style={{ marginRight: 10 }}>{props.icon}</Mui.Icon>
          <span>{props.heading}</span>
        </Mui.Grid>
      </>
    );
  };

  // const MemoizedProfileFields = React.memo(ProfileFields);

  const ProfilePanel = () => {
    return (
      <>
        <Mui.Tabs
          value={subTabIndex}
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, value) => setSubTabIndex(value)}
          aria-label="disabled tabs example"
        >
          <Mui.Tab label="Classes" />
          <Mui.Tab label="Personal Information " />
        </Mui.Tabs>
        <SwipeableViews
          axis={"x-reverse"}
          index={subTabIndex}
          onChangeIndex={(index) => setSubTabIndex(index)}
        >
          <TabPanel value={subTabIndex} index={0}></TabPanel>
          <TabPanel value={subTabIndex} index={1}></TabPanel>
        </SwipeableViews>
      </>
    );
  };

  const ProfileTabs = () => {
    return (
      <Mui.Box
        className="profile-tabs"
        sx={{ bgcolor: "background.paper", width: "100%" }}
      >
        <Mui.AppBar position="static" className="profile-bar">
          <Mui.Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            textColor="inherit"
            classes={{ indicator: "indicator" }}
            inkBarStyle={{ background: "blue" }}
            aria-label="full width tabs example"
          >
            <Mui.Tab
              label={<Heading icon="list" heading="Class details" />}
              {...a11yProps(0)}
            />
            <Mui.Tab
              label={<Heading icon="person" heading="Personal Information" />}
              {...a11yProps(1)}
            />
            <Mui.Tab
              label={<Heading icon="paid" heading="Sales and Income" />}
              {...a11yProps(2)}
            />
            <Mui.Tab
              label={<Heading icon="thumb_up" heading="Followers" />}
              {...a11yProps(3)}
            />
          </Mui.Tabs>
        </Mui.AppBar>

        <SwipeableViews
          axis={"x-reverse"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0}>
            <ClassesInformation
              handleProfileUpdate={handleProfileUpdate}
              uploadButton={uploadButton}
              newProfilePictureBlob={newProfilePictureBlob}
              profile_picture={profile_picture}
              profile={profile}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ProfileFields
              handleProfileUpdate={handleProfileUpdate}
              uploadButton={uploadButton}
              newProfilePictureBlob={newProfilePictureBlob}
              profile_picture={profile_picture}
              profile={profile}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <EarningTable />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <h2 style={{ marginBottom: 20 }}>My Followers</h2>
            <Followers />
          </TabPanel>
        </SwipeableViews>
      </Mui.Box>
    );
  };

  const handleNewImageUploaded = (newPicture) => {
    newPicture.url.then((res) => {
      setNewProfilePicture(res);
    });
    setNewProfilePictureBlob(newPicture.blob);
    // console.log(newProfilePicture)
    setShowProfileCropper(false);
  };

  const closeProfileCropper = () => {
    setShowProfileCropper(false);
  };

  return (
    <>
      {showProfileCropper && (
        <ProfileCropper
          closeProfileCropper={closeProfileCropper}
          handleNewImageUploaded={handleNewImageUploaded}
          profile_picture={
            profile_picture != null &&
            (profile.user_details.auth_using == "social"
              ? profile.user_details.profile_picture
              : process.env.REACT_APP_API_URL +
                "uploads/profilePictures/" +
                profile.profile_picture)
          }
        />
      )}
      <Mui.Grid container className="profile-page">
        <Mui.Grid item xs={12} sm={12}>
          <div className="cover-bg"></div>
          <Mui.Container maxWidth={"lg"}>
            <Mui.Grid
              container
              alignItems="center"
              className="basic-info-wrapper"
              justifyContent="space-between"
            >
              <div className="left-wrapper">
                <div className="profile-pic">
                  {/* <input accept="image/*" id="upload-profile-picture" onChange={handleImageChange} type='file' hidden />
                                    <label htmlFor="upload-profile-picture" > */}
                  <Mui.Tooltip placement="left" title={"Click to change"}>
                    <Mui.Button
                      onClick={() => setShowProfileCropper(true)}
                      component="span"
                      className="profile-picture-button"
                    >
                      {newProfilePicture == null ? (
                        profile_picture || pictureChanged ? (
                          !pictureChanged ? (
                            profile.user_details.auth_using == "social" ? (
                              <img
                                width="100%"
                                className="some"
                                src={profile.user_details.profile_picture}
                              />
                            ) : (
                              <img
                                width="100%"
                                src={
                                  profile != null &&
                                  process.env.REACT_APP_API_URL +
                                    "uploads/profilePictures/" +
                                    profile.profile_picture
                                }
                              />
                            )
                          ) : (
                            <img
                              src={URL.createObjectURL(profile_picture)}
                              width="100%"
                            />
                          )
                        ) : (
                          <Mui.Icon className="picture-icon">
                            photo_camera
                          </Mui.Icon>
                        )
                      ) : (
                        <img src={newProfilePicture} width="100%" />
                      )}
                    </Mui.Button>
                  </Mui.Tooltip>
                  {/* </label> */}
                </div>
                <div className="basic-info">
                  <Mui.Typography variant="h5">
                    {profile != null && profile.user_details.display_name}
                  </Mui.Typography>
                  <Mui.Typography variant="body2">
                    {profile != null && profile.user.email}
                  </Mui.Typography>
                </div>
              </div>
            </Mui.Grid>
            {teacherNotification && (
              <Mui.Grid style={{ marginTop: 10 }} className="request-area">
                {/* <Alert severity="warning">
                                    <AlertTitle>Request from admin!</AlertTitle>
                                    {adminRequest != null &&
                                        <p>{adminRequest[0].request_text}</p>
                                    }
                                </Alert> */}
                <Alert
                  action={
                    <Mui.Button
                      onClick={() => {
                        uploadButton.current?.click();
                      }}
                      style={{
                        backgroundColor: "#FFA129",
                        paddingLeft: 10,
                        paddingRight: 10,
                        color: "white",
                      }}
                      color="warning"
                      size="small"
                    >
                      Upload Now
                    </Mui.Button>
                  }
                  severity="warning"
                >
                  <AlertTitle>Request from admin!</AlertTitle>
                  {adminRequest != null &&
                    adminRequest.map((request, index) => {
                      return (
                        <div
                          style={{ display: "flex", marginBottom: 10 }}
                          className="list-item"
                        >
                          <strong style={{ marginRight: 10 }}>
                            {index + 1}-{" "}
                          </strong>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: request.request_text,
                            }}
                          ></div>
                        </div>
                      );
                    })}
                </Alert>
              </Mui.Grid>
            )}
            <ProfileTabs />
            {/* <Mui.Grid container className="other-info-wrapper">
                            <Mui.Grid item xs={12} sm={3} className="border-outline">
                                <Mui.Typography variant="body1">
                                    +2789 002974 7748
                                </Mui.Typography>
                                <Mui.Typography variant="body1">some@email.com</Mui.Typography>
                            </Mui.Grid>
                            <Mui.Grid item xs={12} sm={6}>
                                <div className="about-wrapper">

                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                    </div>
                            </Mui.Grid>
                        </Mui.Grid> */}
          </Mui.Container>
        </Mui.Grid>
        <Mui.Grid item xs={12} sm={12}>
          <Mui.Container maxWidth="lg"></Mui.Container>
        </Mui.Grid>
      </Mui.Grid>
    </>
  );
}
